import React, { useState, useEffect, useContext } from 'react';
import { useForm } from '../../hooks/useForm.tsx';
import { Link } from 'react-router-dom';


//icons
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

//imagen
import fondo from "static/images/home/register.jpg";
import fondoMovil from "static/images/home/Register-Movil.jpg";
import  miceven1  from "static/Miceven-logo.svg";

//estilos
import './Login.css'

function isValidEmail(email) {
  // Expresión regular para validar un correo electrónico
  const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;

  // Usamos test() para verificar si el correo electrónico coincide con la expresión regular
  return emailRegex.test(email);
}

function Register() {

  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [errorTrim, setErrorTrim] = useState(false);
  const [messageEmail, setMessageEmail] = useState("");
  const [messageContraseña, setMessageContraseña] = useState("");

  //Mensaje de Bienvenida
  const [welcomeMessage, setWelcomeMessage] = useState("");
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(false);

  

  /* Hook de formulario */
  const { email, password, onChange } = useForm({
    email: '',
    password: ''
  });






  /* Método de inicio de sesión */
  const onLogin = () => {
    let hasError = false; // Variable para rastrear si hay errores
  };


  useEffect(() => {
    document.title= `Únete Hoy | Miceven`;
  },[])



  return (
    <>
    <div className='body-container-MainHome-Login'>
      <img src={fondo} className="img-Login"/>
      <img src={fondoMovil} className="img-Login-Movil"/>
    </div> 
      <div className='Container__Login-main'>

              <form className='Container__Form-Register'>
                {/* Titulo */}
                <div className='Title__Container'>
                  <h2 className='title__home__White-Login'>Regístrate</h2>
                </div>

                {/* Nombre */}
                <div className='Container__Label-Register'>
                  <label htmlFor="email" className='Label__Form-Login'>Nombre Completo</label>
                  <input
                    type="text"
                    className={'Input__Form-Login'}
                    id="email"
                    placeholder="Ingrese su Nombre"
                    value={email}
                    onChange={(e) => onChange(e.target.value, 'email')}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        onLogin();
                      }
                    }}
                    autoComplete="off"
                  />

                  
                </div>

                {/* Correo */}
                <div className='Container__Label-Register'>
                  <label htmlFor="email" className='Label__Form-Login'>Correo Electrónico</label>
                  <input
                    type="text"
                    className={'Input__Form-Login'}
                    id="email"
                    placeholder="Ingrese su correo electrónico"
                    value={email}
                    onChange={(e) => onChange(e.target.value, 'email')}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        onLogin();
                      }
                    }}
                    autoComplete="off"
                  />

                  
                </div>

                {/* Contraseña */}
                <div className='Container__Label-Register'>
                  <label htmlFor="password" className='Label__Form-Login'>Contraseña</label>
                  <div className='PasswordInputContainer'>
                    <input
                      type={showPassword ? 'text' : 'password'} // Cambia el tipo de entrada según el estado showPassword
                      className={'Input__Form-Login'}
                      id="password"
                      placeholder="Ingrese su contraseña"
                      value={password}
                      onChange={(e) => onChange(e.target.value, 'password')}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          onLogin();
                        }
                      }}
                      autoComplete="off"
                    />
                    <div
                      className='ShowPasswordIcon' // Estilo para el ícono de mostrar/ocultar contraseña
                      onClick={() => setShowPassword(!showPassword)} // Cambia el estado showPassword al hacer clic en el ícono
                    >
                      {showPassword ? <AiFillEye className='iconJustify'/> : <AiFillEyeInvisible className='iconJustify'/> } 
                    </div>
                  </div>

                </div>

                <div className='Label__Form-basic' >¿Ya tienes una cuenta? <Link className='Label__Form-Link' to={'/entrar'}> Inicia sesión </Link> </div> 

                {/* Boton */}
                <div className='container__button-Login'>
                  <button className='btn-outline-Main' type="button" onClick={onLogin}>
                    Registrate
                  </button>
                </div>
                        
                
              </form>

              
      </div>
      
    </>
    
  );
}

export default Register;
