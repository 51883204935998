import React from 'react';

//Estilos  
import './CarrouselHomeMain.css';
import Carousel from 'react-bootstrap/Carousel';
import { useMediaQuery } from 'react-responsive';
import HarinaBlanca from 'components/Home/HarinaBlanca';


//Iconos
import { AiOutlineRight, AiOutlineLeft  } from "react-icons/ai";


/* Botones del carrusel */

const CustomNextButton = ({ onClick }) => (
    <button className="custom-carousel-button-Pack next" onClick={onClick}>
        <AiOutlineRight style={{color:'#096a01'}} />
    </button>
);

const CustomPrevButton = ({ onClick }) => (
    <button className="custom-carousel-button-Pack prev" onClick={onClick}>
        <AiOutlineLeft  style={{color:'#096a01'}} />
    </button>
);

const CarrouselHomeMain = () => {


    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-device-width: 1224px)'
      });
      
      const isTabletOrMobile = useMediaQuery({
        query: '(max-width: 1224px)'
      });

      
    return (
        <>
            {/* Titulo de la Sección */}
            <div className='contaniner-info-Product' style={{backgroundColor:'#f4f6e4'}} >
                <h1 className='title__home__Protest-center-dark' data-aos="fade-right" data-aos-once="true" data-aos-duration="800">
                    Conoce Nuestros Productos
                </h1>

                <div className='container-text-Products'>
                    <div className='container-text-Products-int'>
                        <p className='text-basic-second' data-aos="fade-left" data-aos-once="true" data-aos-duration="800">
                            Marca Kaly hace presencia en el mercado nacional en sus dos presentaciones de harina 
                            precocida de maíz blanco y amarillo, ganado la confianza y aceptación de sus clientes 
                            por su calidad. 
                        </p>
                    </div>
                </div>
            </div>

            <div className='contaniner-info-Product-Movil' style={{backgroundColor:'#f4f6e4'}} >
                <h1 className='title__home__Protest-center-dark' data-aos="fade-down" data-aos-once="true" data-aos-duration="800">
                    Conoce Nuestros Productos
                </h1>

                <div className='container-text-Products'>
                    <div className='container-text-Products-int'>
                        <p className='text-basic-second' data-aos="fade-up" data-aos-once="true" data-aos-duration="800">
                            Marca Kaly hace presencia en el mercado nacional en sus dos presentaciones de harina 
                            precocida de maíz blanco y amarillo, ganado la confianza y aceptación de sus clientes 
                            por su calidad. 
                        </p>
                    </div>
                </div>
            </div>

            {/* Harina Blanca */}
            <div className='carusel-center-main-Home' style={{backgroundColor:'#e6bd0d', paddingTop:'7rem'}} > 
                <Carousel
                    Cube
                    indicators={false}
                    nextIcon={false}
                    prevIcon={false}
                    className="rounded-carousel" // Agregar esta clase
                >

                        <Carousel.Item interval={2500} key={`${'component'}-${'desktop'}-${'index-two'}`}>
                                {/* Banner Principal 
                                <div className='body-container-BackgroundImg-Banner'></div>
                                */}
                                <div className='container-Harina-main' >
                                    <div className='Container-3d' >
                                        <HarinaBlanca Material={"Verde"} Tamaño={isDesktopOrLaptop ? "Desktop" : "Movil"} />
                                    </div>
    
                                    <div className='Container-title-video' > 
                                        <h1 className='title-video' >
                                        Harina de Maíz Blanca, ¡Calidad <span className='title-basic-next-border-Pack' > Incomparable! </span> 
                                        </h1>
                                    </div>
                                </div>
                                
                        </Carousel.Item>
                </Carousel>
                
            </div>

            {/* Harina Amarilla */}
            <div className='carusel-center-main-Home' style={{backgroundColor:'#e6bd0d', paddingBottom:'10rem'}} > 
                <Carousel
                    Cube
                    indicators={false}
                    nextIcon={false}
                    prevIcon={false}
                    className="rounded-carousel" // Agregar esta clase
                >
                        <Carousel.Item interval={2500} key={`${'component'}-${'desktop'}-${'index'}`}>
                                <div className='container-Harina-main'  >
                                    <div className='Container-3d' >
                                        <HarinaBlanca Material={"Naranja"} Tamaño={isDesktopOrLaptop ? "Desktop" : "Movil"} />
                                    </div>

                                    <div className='Container-title-video-N'> 
                                        <h1 className='title-video-N' >
                                            Harina de Maiz Amarilla, ¡Más Suave, Más Sabrosa, 
                                            <span className='title-basic-next-border-Pack-N' > Puro Maíz! </span> 
                                        </h1>
                                    </div>
                                </div> 
                        </Carousel.Item>

                </Carousel>
                
            </div>
           
        </>
        
    );
}




export default CarrouselHomeMain;

