import React from 'react';
import './Compromiso.css';

//imagenes
import nosotros from 'static/images/home/Destacamos.jpg';


const Destacamos = () => {
  return (
    <>
    <div className='body-container-dest'>

      <div className='comp-container' style={{ backgroundColor: '#f4f6e4'}} >

        {/* Imagen Principal Desktop */}
        <div
            className='comp-wrap-container-two'
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-once="true" 
            data-aos-duration="600"
          >
            <a className='comp-wrap-two' >
                <img
                  className='comp-img-two'
                  alt='Sede AgroMax'
                  src={ nosotros }
                />
            </a>

        </div>

        {/* Imagen Principal Movil*/}
        <div
            className='comp-wrap-container-two--Movil'
            style={{ zIndex:'1' }}
            data-aos="fade-down"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-once="true" 
            data-aos-duration="800"
          >
            <a className='comp-wrap-two' >
                <img
                  className='comp-img-two'
                  alt='Sede AgroMax'
                  src={ nosotros }
                />
            </a>

        </div>


          {/* Info */}
          <div className='comp-wrap-Info-two'>

                <h1 className='title__home__Protest'
                  data-aos="fade-down"
                  data-aos-offset="200"
                  data-aos-easing="ease-in-sine"
                  data-aos-once="true" 
                  data-aos-duration="500"
                > 
                Destacamos en el Mercado Venezolano
                </h1>
                
              <div className='comp-text'>
                  <p className='text-basic-second-c'
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-easing="ease-in-sine"
                    data-aos-once="true" 
                    data-aos-duration="500"
                  >
                  Contamos con presencia en 22 estados del territorio Nacional
                  </p>
                  { /*Btn de Funciones 
                  <div className='btn-featuredProducts' data-aos="zoom-in" data-aos-once="true" data-aos-duration="1000">
                    <a href='#' style={{textDecoration:'none'}}>
                      <button className='btn-outline-Main'
                        >Descubre Más
                      </button>
                    </a>
                  </div>
                  */ }
              </div>
          </div>

          
      </div>
    </div>
  </>
  )
}

export default Destacamos