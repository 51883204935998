import React, {useState} from 'react';
import { TypeAnimation } from 'react-type-animation';
import './MicevenNosotros.css'
import  miceven1  from "../../static/Miceven-logo.svg";

const MicevenNosotros = () => {

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  
  return (
    <>
        <div className='body-container'>
            <div className='Logo-fundation-container-Nosotros'>
              
              <div className='Logo-main-container'>
                <img src={miceven1}  className="img-main-Nosotros" alt="miceven" 
                  data-aos="fade-up" data-aos-once="true" data-aos-duration="800"
                />
              </div>
                 

                <div className='container-nosotros-title'>
                      <h1 className='title__home__Protest-center' 
                      data-aos="fade-up" data-aos-once="true" data-aos-duration="800"
                      >
                        <TypeAnimation
                          sequence={[
                            // Same substring at the start will only be typed once, initially
                            "Nosotros"   ,
                            1500,
                            "Somos MICEVEN C.A."  ,
                            1500,
                            ""  ,
                            1000,
                            "Compromiso y Calidad"  ,
                            1500,
                          ]}
                          speed={40}
                          repeat={Infinity}
                        />
                      </h1>
                      <div className='container-text-nosotros'>
                        <p className='text-basic-second'
                          data-aos="fade-down" data-aos-once="true" data-aos-duration="800"> 
                            Somos una empresa agroindustrial ubicada en Araure, Estado Portuguesa, comprometida con  
                            el desarrollo económico y social del país, dedicada a la producción de harina precocida de maíz.
                        </p>
                      </div>
                </div>
            </div>
        </div>

    </>
  )
}

export default MicevenNosotros