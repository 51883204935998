import React from 'react';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './LineComponent.css';

//imagenes 
import kaly from 'static/images/company/Imagen1.jpg';
import LineTime01 from 'static/images/company/LineTime01.jpg';
import LineTime02 from 'static/images/company/LineTime02.jpg';
import LineTime03 from 'static/images/company/LineTime03.jpg';
import LineTime04 from 'static/images/company/LineTime04.jpg';
import LineTime05 from 'static/images/company/LineTime05.jpg';
import LineTime06 from 'static/images/company/LineTime06.jpg';

//icons
import { AiOutlineRight } from "react-icons/ai";


const LineComponent = () => {
  return (
    <>

      <h1 className='title__home__Protest-center LineContainer' data-aos="fade-up" data-aos-once="true" data-aos-duration="800" >
            Nuestra Evolución  
      </h1>
    <div className='Container__LineComponent__Main' >
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#d9f9e1', color: '#236b0d' }}
          contentArrowStyle={{ borderRight: '7px solid  #236b0d' }}
          date="Febrero 2019"
          iconStyle={{ background: ' #236b0d', color: '#fff' }}
          icon={<AiOutlineRight />}
        >
          <img src={LineTime01} alt="" className='imgTimeLine' />
          <h3 className="vertical-timeline-element-title title-basic-company" style={{color:"#236b0d"}}>Constitución de la Empresa</h3>
          <h4 className="vertical-timeline-element-subtitle"></h4>
        {/*<p className='text-basic-company'>
          Miceven C.A. fue fundada en 2019 con el objetivo inicial de prestar
          servicios de maquila para la producción de harina de maíz precocida a 
        través de la marca Kaly..
          </p>*/}
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#236b0d', color: '#236b0d' }}
          contentArrowStyle={{ borderRight: '7px solid  #174f30' }}
          date="Noviembre 2019    "
          iconStyle={{ background: ' #174f30', color: '#fff', borderRight: '7px solid  #174f30' }}
          icon={<AiOutlineRight />}
          
        >
          <img src={LineTime02} alt="" className='imgTimeLine'/>
          <h3 className="vertical-timeline-element-title title-basic-company" style={{color:"white"}}>Inicio de operaciones de Planta 1</h3>
          <h4 className="text-basic-company">Cap. Inst. 200 /día</h4>
          {/*<p className='text-basic-company'>
          Se realizó el montaje de la  primera planta procesadora de maíz por 
          molinería estándar: recepción y almacenamiento de maíz, limpieza y 
          desgerminación, acondicionamiento, laminación, molienda, empaquetado y 
          despacho. Inició con capacidad de procesar 200 toneladas de maíz por día. 
          </p> */}
        </VerticalTimelineElement>


        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#d9f9e1', color: '#236b0d' }}
          date="Diciembre 2020"
          iconStyle={{ background: ' #174f30', color: '#fff' }}
          icon={<AiOutlineRight />}
        >
          <img src={LineTime03} alt="" className='imgTimeLine'/>
          <h3 className="vertical-timeline-element-title title-basic-company" style={{color:"#236b0d"}}>
              Inicio de operaciones de Planta 2
          </h3>
          <h4 className="text-basic-company" style={{color:"#236b0d"}}> Cap. Inst. 300 T/día </h4>
          {/* <p className='text-basic-company'>
                Luego de la aceptación en el mercado se ejecutó el montaje de las segunda  planta 
              </p> */}
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#236b0d', color: '#236b0d' }}
          date="Enero 2021"
          iconStyle={{ background: ' #174f30', color: '#fff' }}
          icon={<AiOutlineRight />}
        >
          <img src={LineTime04} alt="" className='imgTimeLine'/>
          <h3 className="vertical-timeline-element-title title-basic-company" style={{color:"white"}}>Inicio de operaciones de Planta Silos</h3>
          <h4 className="text-basic-company">Cap. Inst. 16.000 T</h4>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          contentStyle={{ background: '#d9f9e1', color: '#236b0d' }}
          date="Junio 2022"
          iconStyle={{ background: ' #174f30', color: '#fff' }}
          icon={<AiOutlineRight />}
        >
          <img src={LineTime05} alt="" className='imgTimeLine' />
          <h3 className="vertical-timeline-element-title title-basic-company" style={{color:"#236b0d"}}>
          Inicio de operaciones de Planta 3A
          </h3>
          <h4 className="text-basic-company" style={{color:"#236b0d"}} >Cap. Inst. 500 T/día</h4>
        </VerticalTimelineElement>
        
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          contentStyle={{ background: '#236b0d', color: '#236b0d' }}
          date="Junio 2023"
          erticalTimelineElement
          iconStyle={{ background: ' #174f30', color: '#fff' }}
          icon={<AiOutlineRight />}
        >
          <img src={LineTime06} alt="" className='imgTimeLine' />
          <h3 className="vertical-timeline-element-title title-basic-company" style={{color:"white"}}>
          Inicio de operaciones de Planta 3B
          </h3>
          <h4 className="text-basic-company"> Cap. Inst.500 T/día </h4>
        </VerticalTimelineElement>

      </VerticalTimeline>
    </div>


    </>
  )
}

export default LineComponent