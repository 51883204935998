import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

//Componentes
import  Navbar  from 'components/common/NavbarF/Navbar';
import Footer from 'components/common/Footer/Footer/Footer'
import { Home } from 'pages/Home';
import { Contact } from 'pages/Contact';
import { Company } from 'pages/Company';
import LoginScene  from 'pages/LoginScene';
import RegisterScene  from 'pages/RegisterScene';
import  Error  from '../pages/Error404';

//Estilos
import '../Styles/GlobalStyles.css'


export const AppRouter = () => {

    return (
        <Router>
            <Navbar component="Navbar" />   
            <div style={{  display:'block' }}>
        
                    <Routes>
                        <Route path="/" element={ <Home /> }></Route>;
                    
                        <Route path="/Contacto" element={ <Contact/> }></Route>
                        <Route path="/Nosotros" element={ <Company/> }></Route>
                        <Route path="/entrar" element={ <LoginScene/> }></Route>
                        <Route path="/registro" element={ <RegisterScene/> }></Route>


                        <Route path="*" element={ <Error/> }></Route>   
                    </Routes>

                    <Footer/>

            </div> 
        </Router>
    )
}
