
import React, { useEffect } from 'react';
import { TypeAnimation } from 'react-type-animation';
import AOS from 'aos'; // Importa la biblioteca AOS
import 'aos/dist/aos.css'; // Importa los estilos de AOS
import Carousel from 'react-bootstrap/Carousel';

import './CarruselDiscover.css';


const CarruselMain = () => {



  useEffect(() => {
    AOS.init(); // Inicializa AOS
    AOS.refresh(); // Refresca AOS después de que se carguen nuevos elementos
  }, []); 


  return (
    <>
    {/* Banner Desktop */}
    <section className='Carrousel__Banner__Desktop'>

      <div className='video_title-container'>
        <h3 className="video_title-Subtitle"
            data-aos="fade-down"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-once="true" 
            data-aos-duration="600"
        >
          <TypeAnimation
                          sequence={[
                            // Same substring at the start will only be typed once, initially
                            ""   ,
                            1500,
                            "Bienvenidos a"  ,
                            1500,
                          ]}
                          speed={40}
                          repeat={Infinity}
         />
          
        </h3> 
        <h1 className="video_title-main"
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-once="true" 
            data-aos-duration="600"
        >MICEVEN  

        </h1>
      </div>

      <Carousel fade indicators={false} nextIcon={false} prevIcon={false} >

            <Carousel.Item interval={1500}  >
                  <div className='desktop-video' >
                          <video
                            src={require(`../../static/images/banner/video-WEB-KALY.mp4`)}
                            autoPlay
                            muted
                            controls={false}
                            loop
                            >
                          </video>
                  </div>
                      
                      <Carousel.Caption>
                      </Carousel.Caption>
            
          </Carousel.Item>
      
      </Carousel>
    </section>

    {/* Banner Movil */}
    <section className='Carrousel__Banner__Movil' >

      <Carousel fade indicators={false} nextIcon={false} prevIcon={false} >
            <Carousel.Item >
              <div className='movil-video'>
                    <video
                        src={require(`../../static/images/banner/video-WEB-KALY.mp4`)}
                        autoPlay
                        muted
                        controls={false}
                        loop
                        style={{height:"100%", width:"100%"}}
                      ></video>
                    <Carousel.Caption>
                    </Carousel.Caption>
              </div>
          </Carousel.Item>
      </Carousel>

      <div className='video_title-container-Movil-img' >
        <h3 className="video_title-Subtitle" 
            data-aos="fade-down"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-once="true" 
            data-aos-duration="800"  > 
          Bienvenidos a 
        </h3> 
        <h1 className="video_title-main" 
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-once="true" 
            data-aos-duration="800">
          <span style={{color:'#f7e923'}} >MICEVEN</span>    
        </h1>
      </div>

    </section>
    </>
  )
}

export default CarruselMain