import React from 'react';

//imagenes
import nosotros from 'static/images/Trayectory/main2.jpg';

//Estilos y diseño
import './UbicacionPLanta.css'
import 'aos/dist/aos.css'; 



const UbicacionPLanta = () => {




  return (
    <>
      <div className='body-container'>

        {/* ----------- Desktop ----------------*/}
        <div className='ub-container' >

            {/* Info */}
            <div className='ub-wrap-Info'
                
            >
              <h2 className='subtitle__home' style={{ textAlign:"start"}}
              data-aos="fade-down"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-once="true" 
              data-aos-duration="800"
              > 
                                                Ubicación
                                        </h2>

                  <h1 className='title__home__Protest'
                  data-aos="fade-up"
                  data-aos-offset="200"
                  data-aos-easing="ease-in-sine"
                  data-aos-once="true" 
                  data-aos-duration="800"
                  style={{ textAlign:"center"}}
                  > 
                  Nuestra planta esta ubicada en la zona industrial de Araure, Portuguesa
                  </h1>
                  
                <div className='us-text'>
                    { /*Btn de Funciones 
                    <div className='btn-featuredProducts' data-aos="zoom-in" data-aos-once="true" data-aos-duration="1000">
                      <a href='#' style={{textDecoration:'none'}}>
                        <button className='btn-outline-Main'
                          >Descubre Más
                        </button>
                      </a>
                    </div>
                    */ }
                </div>
            </div>

            {/* Imagen Principal */}
            <div
              data-aos="fade-left"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-once="true" 
              data-aos-duration="800"
            >
              <a className='ub-wrap' >
                  <img
                    className='ub-img'
                    alt='Sede AgroMax'
                    src={ nosotros }
                  />
              </a>

            </div>
        </div>

        {/* ----------- Movil ----------------*/}
        <div className='ub-container-Movil' >

          {/* Imagen Principal */}
          <div
              data-aos="fade-up"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-once="true" 
              data-aos-duration="800"
            >
              <a className='ub-wrap' >
                  <img
                    className='ub-img'
                    alt='Sede AgroMax'
                    src={ nosotros }
                  />
              </a>

            </div>

            {/* Info */}
            <div className='ub-wrap-Info-Movil'>
              <h2 className='subtitle__home' style={{ textAlign:"start"}}
              data-aos="fade-down"
              data-aos-offset="200"
              data-aos-easing="ease-in-sine"
              data-aos-once="true" 
              data-aos-duration="800"
              > 
                Ubicación
              </h2>

                  <h1 className='title__home__Protest'
                  data-aos="fade-up"
                  data-aos-offset="200"
                  data-aos-easing="ease-in-sine"
                  data-aos-once="true" 
                  data-aos-duration="800"
                  > 
                  Nuestra planta esta ubicada en la zona industrial de Araure, Portuguesa
                  </h1>
                  
                <div className='us-text'>
                    { /*Btn de Funciones 
                    <div className='btn-featuredProducts' data-aos="fade-left" data-aos-once="true" data-aos-duration="700">
                      <a href='#' style={{textDecoration:'none'}}>
                        <button className='btn-outline-Main'
                          >Descubre Más
                        </button>
                      </a>
                    </div>
                    */ }
                </div>
            </div>

            
        </div>




      </div>
    </>
  )
}

export default UbicacionPLanta;

