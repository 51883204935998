import React from 'react';
import './Compromiso.css';

//imagenes
import nosotros from 'static/images/home/compromiso.jpg';


const Compromiso = () => {
  return (
    <>
    <div className='body-container-two'>

      <div className='comp-container-two' >

        {/* Imagen Principal Desktop*/}
        <div
            className='comp-wrap-container-two'
            data-aos="fade-left"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-once="true" 
            data-aos-duration="500"
          >
            <a className='comp-wrap-two' >
                <img
                  className='comp-img-two'
                  alt='Sede AgroMax'
                  src={ nosotros }
                />
            </a>

        </div>

    {/* Imagen Principal Movil*/}
    <div
            className='comp-wrap-container-two--Movil'
            style={{ zIndex:'-1' }}
            data-aos="fade-down"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-once="true" 
            data-aos-duration="800"
          >
            <a className='comp-wrap-two' >
                <img
                  className='comp-img-two'
                  alt='Sede AgroMax'
                  src={ nosotros }
                />
            </a>

        </div>


          {/* Info */}
          <div className='comp-wrap-Info-two'>

                <h1 className='title__home__Protest'
                              data-aos="fade-down"
                              data-aos-offset="200"
                              data-aos-easing="ease-in-sine"
                              data-aos-once="true" 
                              data-aos-duration="400"
                > 
                ¡Nuestro compromiso es llevar calidad y amor a tu mesa!
                </h1>
                
              <div className='comp-text'>
                  <p className='text-basic-second-c'
                      data-aos="fade-up"
                      data-aos-offset="200"
                      data-aos-easing="ease-in-sine"
                      data-aos-once="true" 
                      data-aos-duration="400"
                  >
                  MiCeven C.A. es una empresa agroindustrial dedicada a la 
                  producción de harina precocida de maíz. 
                  </p>
                  { /*Btn de Funciones 
                  <div className='btn-featuredProducts' data-aos="zoom-in" data-aos-once="true" data-aos-duration="1000">
                    <a href='#' style={{textDecoration:'none'}}>
                      <button className='btn-outline-Main'
                        >Descubre Más
                      </button>
                    </a>
                  </div>
                  */ }
              </div>
          </div>


          
      </div>
    </div>
  </>
  )
}

export default Compromiso