import React, { useState, useEffect } from 'react';

//componentes  
import   CarruselMain               from 'components/CarruselHomeMain/CarruselMain';
import   MicevenNosotros            from "components/Company/MicevenNosotros";
import   LineComponent              from 'components/Company/LineComponent';
import   ClientesNacional           from "components/Company/ClientesNacional";
import   AlianzasDistribucion       from "components/Company/AlianzasDistribucion";
import   CarrouselHomeMain          from 'components/Home/CarrouselHomeMain';

//Manejo de Carga y Error
import Loader     from "components/Loader/Loader";
import ErrorPage  from "components/ErrorPage/ErrorPage"; 


export const Company = () => {

  //Variables de Carga
  const [loanding, setLoanding] = useState(false);
  const [error, setError] = useState(null); 

  //Peticion principal 
  const getHome = async () => {
    try {
      //Se inicializa el componente "Cargando"
      setLoanding(true);

       // Verificar conexión a Internet
       if (!navigator.onLine) {
        throw new Error('No hay conexión a Internet');
      }

      // Simular una pequeña demora antes de desactivar el loader (500ms en este ejemplo)
      setTimeout(() => {
        //Desactiva el componente "Cargando" y no se carga ningún error 
        setLoanding(false);
        setError(null);
      }, 2000); // 500ms de retraso

    } catch (error) {
      setLoanding(false);
      if (error.message) {
        setError(`${error.message}`);
      } else if (error.request) {
        setError('No se pudo conectar con el servidor');
      } else {
        setError('Error desconocido');
      }
    }
  };

   //Peticion para cargar en el top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth" // Esto hace que el desplazamiento sea suave
    });
  };


  useEffect(() => {
    document.title= `Nosotros - Miceven`
    getHome();
    scrollToTop();
  },[])

  
  return (
    <>
      <div style={{ background: '' }}>
      {loanding ? (
        <Loader />
      ) : error ? (
        <ErrorPage message={error} />
      ) : (
        <>

          <CarruselMain />
          <MicevenNosotros/>
          <CarrouselHomeMain/>
          <ClientesNacional />
          <AlianzasDistribucion />
          <LineComponent />      

        </>
      )}
    </div>
    </>
  )
}
