import React, { useState, useEffect } from 'react'; 

//Componentes Principales  
import  BannerMainCarruselHome     from 'components/Home/BannerMainCarruselHome';
import  AboutUs                    from 'components/Home/AboutUs';
import  Compromiso                 from 'components/Home/Compromiso';
import  Destacamos                 from 'components/Home/Destacamos';
import  MicevenP                   from 'components/Home/MicevenP';
import  UbicacionPLanta            from 'components/Home/UbicacionPLanta';
import  ContactSection             from 'components/Home/Contact/ContactSection';

//Manejo de Carga y Error
import Loader     from "components/Loader/Loader";
import ErrorPage  from "components/ErrorPage/ErrorPage"; 



export const Home = () => {

  //Variables de Carga
  const [loanding, setLoanding] = useState(false);
  const [error, setError] = useState(null); 


  //Peticion principal 
  const getHome = async () => {
    try {
      //Se inicializa el componente "Cargando"
      setLoanding(true);

       // Verificar conexión a Internet
       if (!navigator.onLine) {
        throw new Error('No hay conexión a Internet');
      }

      // Simular una pequeña demora antes de desactivar el loader (500ms en este ejemplo)
      setTimeout(() => {
        //Desactiva el componente "Cargando" y no se carga ningún error 
        setLoanding(false);
        setError(null);
      }, 2000); // 500ms de retraso

    } catch (error) {
      setLoanding(false);
      if (error.message) {
        setError(`${error.message}`);
      } else if (error.request) {
        setError('No se pudo conectar con el servidor');
      } else {
        setError('Error desconocido');
      }
    }
  };
  
  //Peticion para cargar en el top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth" // Esto hace que el desplazamiento sea suave
    });
  };



  useEffect(() => {
    document.title= `Miceven`;
    getHome();
    scrollToTop();
  },[])


  return (
    <div >
<>
      <div style={{ background: '' }}>
      {loanding ? (
        <Loader />
      ) : error ? (
        <ErrorPage message={error} />
      ) : (
        <>
            <BannerMainCarruselHome /> 
            <AboutUs/>
            <MicevenP/>
            <Compromiso/>
            <Destacamos/>
            <UbicacionPLanta/>
            <ContactSection  />

            {/*
            
            */}
            
            
        </>
      )}
    </div>
    </>
        
    </div>
  )
}






