import React, { useState, useEffect } from 'react';

//Componentes
import { BannerMain }       from 'components/BannerMain/BannerMain';
import Companys             from 'components/Contact/Companys';
import ContactSection       from 'components/Contact/ContactForm/ContactSection';

//Manejo de Carga y Error
import Loader     from "components/Loader/Loader";
import ErrorPage  from "components/ErrorPage/ErrorPage"; 



export const Contact = () => {

  //Variables de Carga
  const [error, setError] = useState(null);
  const [loanding, setLoanding] = useState(false);


 //Peticion principal 
  const getHome = async () => {
    try {
      //Se inicializa el componente "Cargando"
      setLoanding(true);

      // Verificar conexión a Internet
      if (!navigator.onLine) {
        throw new Error('No hay conexión a Internet');
      }

      // Simular una pequeña demora antes de desactivar el loader (500ms en este ejemplo)
      setTimeout(() => {
        //Desactiva el componente "Cargando" y no se carga ningún error 
        setLoanding(false);
        setError(null);
      }, 2000); // 500ms de retraso

    } catch (error) {
      setLoanding(false);
      if (error.message) {
        setError(`${error.message}`);
      } else if (error.request) {
        setError('No se pudo conectar con el servidor');
      } else {
        setError('Error desconocido');
      }
    }
  };

  //Peticion para cargar en el top
  const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth" // Esto hace que el desplazamiento sea suave
      });
  };


  useEffect(() => {
    document.title= `Contacto - Miceven`
    getHome();
    scrollToTop();
  },[])


  return (
    <>
      <div style={{ background: '' }}>
      {loanding ? (
        <Loader />
      ) : error ? (
        <ErrorPage message={error} style={{ background: '#04160c' }} />
      ) : (
        <>
          <BannerMain /> 
          <Companys Loanding={ loanding }/>
          <ContactSection />
        </>
      )}
    </div>
    </>
  )
}
