import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import './TopInfo.css';

import { BsPerson, BsQuestionLg, BsPersonPlus, BsCursor, BsWhatsapp, BsXLg   } from "react-icons/bs";


export const TopInfo = () => {

    //Variables de estado
    const [click, setClick] = useState(false); // estado del dropdown
    const [dropdownHelp, setDropdownHelp] = useState(false); // estado del dropdown

    const dropdownRef = useRef(null); // Referencia para la dropdown
    const dropdownHelpRef = useRef(null); // 





    //Btn Sección Usuario
    const UserOption = () => setClick(!click);

    //Btn Sección Ayuda
    const HelpOption = () => setDropdownHelp(!dropdownHelp);



    



    // Método para bloquear scroll cuando el usuario abra la ventana desplegable!
     useEffect(() => {
        if (click) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [click]);


    // Método para clickear en cualquier parte del body se cierre la ventana desplagable!
    useEffect(() => {
        const handleClickOutside = (event) => {
            // Si la dropdown está abierta y el clic ocurre fuera de la dropdown, ciérrala
            if (click && !dropdownRef.current.contains(event.target)) {
                setClick(false);
            }
        };

        if (click) {
            document.addEventListener('click', handleClickOutside);
        }

        // Limpieza: eliminar el event listener cuando la dropdown se cierra
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [click]);
    



    // Método para bloquear scroll cuando el usuario abra la ventana desplegable!
    useEffect(() => {
        if (dropdownHelp) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [dropdownHelp]);


    // Método para clickear en cualquier parte del body se cierre la ventana desplagable!
    useEffect(() => {
        const handleClickOutsideHelp = (event) => {
            // Si la dropdown está abierta y el clic ocurre fuera de la dropdown, ciérrala
            if (dropdownHelp && !dropdownHelpRef.current.contains(event.target)) {
                setDropdownHelp(false);
            }
        };

        if (dropdownHelp) {
            document.addEventListener('click', handleClickOutsideHelp);
        }

        // Limpieza: eliminar el event listener cuando la dropdown se cierra
        return () => {
            document.removeEventListener('click', handleClickOutsideHelp);
        };
    }, [dropdownHelp]);


  return (
    <>
        <div className='containerTop'>
            <div className='content-Top'>
                <BsPerson className='icon-Top-Person'  onClick={UserOption} />
                <BsQuestionLg  className='icon-Top'    onClick={HelpOption} />
            </div>
        </div>

        

        {/* Sección Usuario */}
        <div className={click ? 'optionDrop-Top active': 'optionDrop-Top' } ref={dropdownRef}>
            <BsXLg onClick={UserOption} className={click ? 'icon-closeDrop': 'icon-closeDrop desactive' } />
            <Link className='optionDrop-Top-Content' to={'/entrar'} onClick={UserOption}>
                {/* Iniciar Sesión */}
                <BsPerson  className={click ? 'icon-optionDrop': 'icon-optionDrop desactive' } />
                <h2 className={click ? 'title-icon-optionDrop': 'title-icon-optionDrop desactive' }>Iniciar Sesión</h2>
            </Link>

            <Link className='optionDrop-Top-Content' to={'/registro'} onClick={UserOption}>
                {/* Crear Cuenta */}
                <BsPersonPlus className={click ? 'icon-optionDrop': 'icon-optionDrop desactive' } />
                <h2 className={click ? 'title-icon-optionDrop': 'title-icon-optionDrop desactive' } >Crear Cuenta</h2>
            </Link>
        </div>


        {/* Sección Ayuda */}
        <div className={dropdownHelp ? 'optionDrop-Top-Help active': 'optionDrop-Top-Help' } ref={dropdownHelpRef}>
        <BsXLg onClick={HelpOption} className={dropdownHelp ? 'icon-closeDrop': 'icon-closeDrop desactive' } />
            {/* Iniciar Sesión */}
            <div className='optionDrop-Top-Content-help'>
                <h2 className={dropdownHelp ? 'title-help': 'title-help desactive' }>¿Necesitas Ayuda?</h2>
            </div>

            <div className='optionDrop-Top-Help-content'>
                <Link className='optionDrop-Top-Content' to={'/Contacto'} onClick={HelpOption}>
                    {/* Contactanos */}
                    <BsCursor  className={dropdownHelp ? 'icon-optionDrop': 'icon-optionDrop desactive' } />
                    <h2 className={dropdownHelp ? 'title-icon-optionDrop': 'title-icon-optionDrop desactive' }>Contactanos</h2>
                </Link>

                <div className='optionDrop-Top-Content'>
                    {/* Crear Cuenta */}
                    <BsWhatsapp  className={dropdownHelp ? 'icon-optionDrop': 'icon-optionDrop desactive' } />
                    <h2 className={dropdownHelp ? 'title-icon-optionDrop': 'title-icon-optionDrop desactive' } >Escribenos</h2>
                </div>
            </div>

        </div>

    </>
  )
}
