import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { motion } from "framer-motion";

import logoMiceven from '../../static/Miceven-logo.svg';

// Estilos de diseño
import "./Loader.css"

const Loader = () => {
  return (
    <div className='loanding__Container'>
      <motion.group 
        initial={{ opacity: 0, x: 0, scale: 0 }} 
        animate={{ opacity: 1, x: 0, scale: 1, rotateZ: [0, 0, 0, 0], scale: [1, 1.1, 1] }} 
        exit={{ opacity: 0 }} 
        transition={{ duration: 2, type: 'spring', stiffness: 100, damping: 15, repeat: Infinity, duration: 3, repeatType: "reverse" }}
        style={{ scale: 1 }} // Escala inicial
      >
        <img src={logoMiceven} className='logo_miceven' />
      </motion.group>
    </div>
  );
}

export default Loader;