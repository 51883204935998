import React from 'react';
import './MicevenP.css'

import  miceven1  from "static/Miceven-logo.svg";

const MicevenP = () => {
  return (
    <>
    {/*   */}
        <div className='container-BackgroundImg'></div>
  

        <div className='body-container-Miceven' style={{backgroundColor:'#e6bd0d'}}>
            <img src={miceven1}  className="img-main" alt="miceven" 
              data-aos="fade-up" data-aos-once="true" data-aos-duration="800"
            />
            {/* Desktop */}
            <div className='Logo-fundation-container'>
                <h1 className='title-basic-next'
                  data-aos="fade-right" data-aos-once="true" data-aos-duration="800"
                > 
                  Fundada a principios  <span className='title-basic-next-border' >del año 2019</span>
                </h1>
                <div className='container-text-main'>
                  <p className='text-basic-second-p'
                    data-aos="fade-left" data-aos-once="true" data-aos-duration="800"
                  > 
                      Nos enfocamos en producir productos de excelente calidad a precios competitivos para contribuir con la consolidación de la soberanía alimentaria de Venezuela. 
                  </p>
                </div>
               
            </div>
           
           {/* Movil */}
           <div className='Logo-fundation-container--Movil'>
                <h1 className='title-basic-next'
                  data-aos="fade-down" data-aos-once="true" data-aos-duration="800"
                > 
                 Fundada a principios del <span className='title-basic-next-border' > año 2019 </span>
                </h1>
                <div className='container-text-main'>
                  <p className='text-basic-second-p'
                    data-aos="fade-down" data-aos-once="true" data-aos-duration="800"
                  > 
                      Nos enfocamos en producir productos de excelente calidad a precios competitivos para contribuir con la consolidación de la soberanía alimentaria de Venezuela. 
                  </p>
                </div>
               
            </div>
           
        </div>
    </>
  )
}

export default MicevenP