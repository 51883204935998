import React from 'react';
import './AlianzasDistribucion.css'

import  miceven1  from "../../static/images/company/micevenF1.png";
import  Forum  from "../../static/images/company/Forum.png";
import  cm  from "../../static/images/company/cm.png";
import  Makro  from "../../static/images/company/Makro.png";
import  unicasa  from "../../static/images/company/unicasa.png";
import  Luvebras  from "../../static/images/company/Luvebras.png";
import  gama  from "../../static/images/company/gama.png";


const AlianzasDistribucion = () => {
  return (
    <>
        <div className='body-container'>
            
            {/* Desktop */}
            <div className='Alianza-fundation-container'>
                <h1 className='title__home__Protest-center'
                  data-aos="fade-right" data-aos-once="true" data-aos-duration="800"
                > 
                  Alianzas de Distribución
                </h1>
                <div className='container-text-main'>
                  <p className='text-basic-second'
                    data-aos="fade-left" data-aos-once="true" data-aos-duration="800"
                  > 
                     Nos enorgullece ser aliados estratégicos de diversas cadenas de distribución, 
                     fortaleciendo así nuestra capacidad de llegar a cada rincón del país.
                  </p>
                </div>

                <div className='container-logo-alianza' data-aos="fade-up" data-aos-once="true" data-aos-duration="800">
                          <img src={Forum}  className="img-main-alianza" alt="miceven"/>
                          <img src={cm}  className="img-main-alianza" alt="miceven"/>
                          <img src={Makro}  className="img-main-alianza" alt="miceven"/>
                          <img src={unicasa}  className="img-main-alianza" alt="miceven"/>
                          <img src={Luvebras}  className="img-main-alianza" alt="miceven"/>
                          <img src={gama}  className="img-main-alianza" alt="miceven"/>
              </div>
               
            </div>

              
                          
           
           {/* Movil */}
           <div className='Alianza-container--Movil'>
                <h1 className='title__home__Protest-center'
                  data-aos="fade-down" data-aos-once="true" data-aos-duration="800"
                > 
                  Alianzas de<span className='title-basic-next-border-Pack-D'>Distribución</span>
                </h1>
                <div className='container-text-main'>
                  <p className='text-basic-second-c'
                    data-aos="fade-down" data-aos-once="true" data-aos-duration="800"
                  > 
                    Nos enorgullece ser aliados estratégicos de diversas cadenas de distribución, 
                    fortaleciendo así nuestra capacidad de llegar a cada rincón del país. 
                  </p>
                </div>

                <div className='container-logo-alianza' data-aos="fade-up" data-aos-once="true" data-aos-duration="800">
                          <img src={Forum}  className="img-main-alianza" alt="miceven"/>
                          <img src={cm}  className="img-main-alianza" alt="miceven"/>
                          <img src={Makro}  className="img-main-alianza" alt="miceven"/>
                          <img src={unicasa}  className="img-main-alianza" alt="miceven"/>
                          <img src={Luvebras}  className="img-main-alianza" alt="miceven"/>
                          <img src={gama}  className="img-main-alianza" alt="miceven"/>
              </div>
               
            </div>
           
        </div>
    </>
  )
}

export default AlianzasDistribucion;