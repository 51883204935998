import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';


//imagenes HarinaMovil
import HarinaKaly from 'static/images/home/HARINAV.png';
import Grano1 from 'static/images/home/GRANO 1 V.png';
import Grano2 from 'static/images/home/GRANO 2 V.png';
import Grano3 from 'static/images/home/GRANO 3 V.png';
import Grano4 from 'static/images/home/GRANO 4 V.png';
import Grano5 from 'static/images/home/GRANO 5 V.png';
import Grano6 from 'static/images/home/GRANO 6 V.png';
import Grano7 from 'static/images/home/GRANO 7 V.png';
import Canvas from './canvas';
import HarinaMovil from './canvas';

//Estilos y diseño
import './MainHome.css'
import 'aos/dist/aos.css'; 


const MainHomeHBlanca = ({Visible}) => {


  const [shouldAnimate, setShouldAnimate] = useState(false);


  useEffect(() => {

    if (Visible) {
      setShouldAnimate(true);
    }

  }, [Visible]);


  useEffect(() => {
    let timer;

    if (!Visible) {
      // Si isVisible sigue siendo falso después de 3 segundos, cambia shouldAnimate a verdadero
      timer = setTimeout(() => {
        setShouldAnimate(true);
      }, 3000);
    }

  }, []);


  return (
    <>


        <>
          {/* Contenido del componente que se muestra cuando está en pantalla */}
          <div className='body-container-BackgroundImgTwo'></div>
          <div className='body-container-MainHome'>

        {/* ----------- Desktop ----------------*/}
        <div className='main-container' >


            {/* Composición Harina de Maiz Amarilla Desktop  */}
            <div className='container-harina-main-V'>
              
                {/* Harina Amarilla */}
                <div  className='empaque__Harina__Verde-New' >
                    <motion.div 
                      initial={{ opacity: 0, x: "-100vw" }} // Comienza desde fuera de la pantalla a la derecha
                      animate={{ 
                                  opacity: shouldAnimate ? 1 : 0, 
                                  x: shouldAnimate ? 0 : "-100vw", 
                                  scale: [shouldAnimate ? 0.5 : 0, 1.4, 1.2], 
                                  rotateZ: [0, shouldAnimate ? -10 : 0, 0] 
                                }} // Se coloca en su posición final con un efecto de rebote
                      exit={{ opacity: 0 }} 
                      transition={{ duration: 2, type: 'spring',
                      delay: shouldAnimate ? 0.2 : 0.5, // Retrasa la animación en 7 segundo
                      stiffness: 100, damping: 15 ,
                      x: { type: 'spring', stiffness: 100, damping: 15 }, // Animación de rebote en el eje x
                      opacity: { duration: 0.5 } // Animación de opacidad
                      }}
                      
                    >
                      <img src={HarinaKaly} className=''/>
                    </motion.div>
                </div>

                {/* Granos de Maiz */}
                <div className='granos__Maiz'>

                  <div className='grano__maizUno' >
                      <motion.div 
                        initial={{ opacity: 0, x: "-100vw" }} // Comienza desde fuera de la pantalla a la derecha
                        animate={{ 
                            opacity: shouldAnimate ? 1 : 0, 
                            x: shouldAnimate ? 0 : "-100vw", 
                            scale: [2.2, 2.2, 2.2] }} // Se coloca en su posición final con un efecto de rebote
                        exit={{ opacity: 0 }} 
                        transition={{ 
                        x: { type: 'spring', stiffness: 20, damping: 5 }, // Animación de rebote en el eje x
                        delay: shouldAnimate ? 0.2 : 0.5 , // Retrasa la animación en 7 segundo
                        opacity: { duration: 0.5 } // Animación de opacidad
                        }}
                      >
                        <img src={Grano1}  />
                      </motion.div>
                  </div>

                  <div className='grano__maizDos' >
                    <motion.div 
                      initial={{ opacity: 0, x: "100vw" }} // Comienza desde fuera de la pantalla a la derecha
                      animate={{ 
                        opacity: shouldAnimate ? 1 : 0, 
                        x: shouldAnimate ? 0 : "100vw", 
                        scale: [2.2, 2.2, 2.2] }} // Se coloca en su posición final con un efecto de rebote
                      exit={{ opacity: 0 }} 
                      transition={{ 
                        x: { type: 'spring', stiffness: 20, damping: 5 }, // Animación de rebote en el eje x
                        delay: shouldAnimate ? 0.2 : 0.5 , // Retrasa la animación en 7 segundo
                      opacity: { duration: 0.5 } // Animación de opacidad
                      }}
                      
                    >
                      <img src={Grano2} className='' />
                    </motion.div>
                  </div>

                  <div className='grano__maizTres' >
                    <motion.div 
                      initial={{ opacity: 0, x: "100vw" }} // Comienza desde fuera de la pantalla a la derecha
                      //animate={{ opacity: 1, x: 0, scale: [0.5, 2.8, 2.2], rotateZ: [0, -10, 0] }} // Se coloca en su posición final con un efecto de rebote
                      animate={{ 
                        opacity: shouldAnimate ? 1 : 0, 
                        x: shouldAnimate ? 0 : "100vw", 
                        scale: [2.2, 2.2, 2.2] }} // Se coloca en su posición final con un efecto de rebote 
                      exit={{ opacity: 0 }} 
                      transition={{ 
                      x: { type: 'spring', stiffness: 20, damping: 5 }, // Animación de rebote en el eje x
                      delay: shouldAnimate ? 0.2 : 0.5 , // Retrasa la animación en 7 segundo
                      opacity: { duration: 0.5 } // Animación de opacidad
                      }}
                      
                    >
                      <img src={Grano3} className='' />
                    </motion.div>
                  </div>

                  <div className='grano__maizCuatro' >
                    <motion.div 
                      initial={{ opacity: 0, x: "-100vw" }} // Comienza desde fuera de la pantalla a la derecha
                      //animate={{ opacity: 1, x: 0, scale: [0.5, 2.8, 2.2], rotateZ: [0, -10, 0] }} // Se coloca en su posición final con un efecto de rebote
                      animate={{ 
                        opacity: shouldAnimate ? 1 : 0, 
                        x: shouldAnimate ? 0 : "-100vw", 
                        scale: [2.2, 2.2, 2.2] }} // Se coloca en su posición final con un efecto de rebote 
                      exit={{ opacity: 0 }} 
                      transition={{ 
                        x: { type: 'spring', stiffness: 20, damping: 5 }, // Animación de rebote en el eje x
                        delay: shouldAnimate ? 0.2 : 0.5 , // Retrasa la animación en 7 segundo
                      opacity: { duration: 0.5 } // Animación de opacidad
                      }}
                      
                    >
                      <img src={Grano4} className='' />
                    </motion.div>
                  </div>

                  <div className='grano__maizCinco' >
                    <motion.div 
                      initial={{ opacity: 0, x: "-100vw" }} // Comienza desde fuera de la pantalla a la derecha
                      //animate={{ opacity: 1, x: 0, scale: [0.5, 2.8, 2.2], rotateZ: [0, -10, 0] }} // Se coloca en su posición final con un efecto de rebote
                      animate={{ 
                        opacity: shouldAnimate ? 1 : 0, 
                        x: shouldAnimate ? 0 : "-100vw", 
                        scale: [2.2, 2.2, 2.2] }} // Se coloca en su posición final con un efecto de rebote 
                      exit={{ opacity: 0 }} 
                      transition={{ 
                        x: { type: 'spring', stiffness: 20, damping: 5 }, // Animación de rebote en el eje x
                        delay: shouldAnimate ? 0.2 : 0.5 , // Retrasa la animación en 7 segundo
                      opacity: { duration: 0.5 } // Animación de opacidad
                      }}
                      
                    >
                      <img src={Grano5} className='' />
                    </motion.div>
                  </div>

                  <div className='grano__maizSeis' >
                    <motion.div 
                      initial={{ opacity: 0, x: "100vw" }} // Comienza desde fuera de la pantalla a la derecha
                      //animate={{ opacity: 1, x: 0, scale: [0.5, 2.8, 2.2], rotateZ: [0, -10, 0] }} // Se coloca en su posición final con un efecto de rebote
                      animate={{ 
                        opacity: shouldAnimate ? 1 : 0, 
                        x: shouldAnimate ? 0 : "100vw", 
                        scale: [2.2, 2.2, 2.2] }} // Se coloca en su posición final con un efecto de rebote 
                      exit={{ opacity: 0 }} 
                      transition={{ 
                        x: { type: 'spring', stiffness: 20, damping: 5 }, // Animación de rebote en el eje x
                        delay: shouldAnimate ? 0.2 : 0.5 , // Retrasa la animación en 7 segundo
                      opacity: { duration: 0.5 } // Animación de opacidad
                      }}
                      
                    >
                      <img src={Grano6} className='' />
                    </motion.div>
                  </div>

                  <div className='grano__maizSiete' >
                    <motion.div 
                      initial={{ opacity: 0, x: "-100vw" }} // Comienza desde fuera de la pantalla a la derecha
                      //animate={{ opacity: 1, x: 0, scale: [0.5, 2.8, 2.2], rotateZ: [0, -10, 0] }} // Se coloca en su posición final con un efecto de rebote
                      animate={{ 
                        opacity: shouldAnimate ? 1 : 0, 
                        x: shouldAnimate ? 0 : "-100vw", 
                        scale: [2.2, 2.2, 2.2] }} // Se coloca en su posición final con un efecto de rebote 
                      exit={{ opacity: 0 }} 
                      transition={{ 
                        x: { type: 'spring', stiffness: 20, damping: 5 }, // Animación de rebote en el eje x
                        delay: shouldAnimate ? 0.2 : 0.5 , // Retrasa la animación en 7 segundo
                      opacity: { duration: 0.5 } // Animación de opacidad
                      }}
                      
                    >
                      <img src={Grano7} className='' />
                    </motion.div>
                  </div>


                </div>
            </div>


            {/* Titulo  */}
            <div className='main__title__container-v'
                data-aos="fade-left"
                data-aos-offset="200"
                data-aos-easing="ease-in-sine"
                data-aos-once="true" 
                data-aos-duration="600"
            >
              <div className='main__title-v'>
                  <h1 className='title-basic-main-prin'> El sabor de la tradición venezolana  en tu mesa  </h1> 
              </div>

                
              <div className='us-text-v'>
                    { /*Btn de Funciones */ }
                    <div className='btn-featuredProducts' data-aos="zoom-in" data-aos-once="true" data-aos-duration="1000">
                      <a href='/Nosotros' style={{textDecoration:'none'}}>
                        <button className='btn-outline-Main'
                          >Descubre Más
                        </button>
                      </a>
                    </div>
                </div>
            </div>

            

        </div>


        {/* ----------- Movil ----------------*/}
        <div className='us-container-Movil' >

          {/* Titulo */}
          <div className='us-wrap-Info-Movil'>
                      <div
                      data-aos="fade-up"
                      data-aos-offset="200"
                      data-aos-easing="ease-in-sine"
                      data-aos-once="true" 
                      data-aos-duration="1300"
                      >
                        <h1 className='title-basic-main-prin-V'> 
                          
                          El sabor de la tradición venezolana en tu mesa          
                        </h1>
                      </div>


                      <div className='us-text'>
                        { /*Btn de Funciones */ }
                        <div className='btn-featuredProducts' data-aos="zoom-in" data-aos-once="true" data-aos-duration="1000">
                          <a href='/Nosotros' style={{textDecoration:'none'}}>
                            <button className='btn-outline-Main'
                              >Descubre Más
                            </button>
                          </a>
                        </div>
                      </div>
                   
          </div>

           {/* Composición Harina de Maiz Amarilla Desktop  */}
           <div className='container-harina-main-movil-V'>
              
              {/* Harina Blanca */}
              <div className='empaque__Harina__Amarilla-New-movil' >
                  <motion.div 
                    initial={{ opacity: 0, x: "5vw" }} // Comienza desde fuera de la pantalla a la derecha
                    //animate={{ opacity: 1, x: 0, y: -20, scale: [0.5, 1, 0.999], rotateZ: [0, -10, 0] }} // Se coloca en su posición final con un efecto de rebote
                    animate={{ 
                      opacity: shouldAnimate ? 1 : 0, 
                      x: shouldAnimate ? 0 : "5vw", 
                      y: shouldAnimate ? -39 : -39,
                      scale: [shouldAnimate ? 0 : 0, 1, 0.88], 
                      rotateZ: [0, -10, 0] 
                    }} // Se coloca en su posición final con un efecto de rebote


                    exit={{ opacity: 0 }} 
                    transition={{ duration: 2, type: 'spring',
                      delay: shouldAnimate ? 0 : 3, // Retrasa la animación en 7 segundo
                      stiffness: 100, damping: 15 ,
                      x: { type: 'spring', stiffness: 100, damping: 15 }, // Animación de rebote en el eje x
                      opacity: { duration: 0.5 } // Animación de opacidad
                    }}
                    
                  >
                    <img src={HarinaKaly} />
                  </motion.div>
              </div>

              {/* Granos de Maiz */}
              <div className='granos__Maiz'>
                <div className='grano__maizUno' >
                    <motion.div 
                      initial={{ opacity: 0, x: "-100vw" }} // Comienza desde fuera de la pantalla a la derecha
                      //animate={{ opacity: 1, x: 0, scale: [0.5, 1.5, 0.7] }} // Se coloca en su posición final con un efecto de rebote
                      animate={{ 
                              opacity: shouldAnimate ? 1 : 0, 
                              x: shouldAnimate ? 0 : "-100vw" , 
                              scale: [ 0.7, 0.7, 0.7], 
                            }} // Se coloca en su posición final con un efecto de rebote
                      exit={{ opacity: 0 }} 
                      transition={{ 
                      x: { type: 'spring', stiffness: 20, damping: 5 }, // Animación de rebote en el eje x
                      delay: shouldAnimate ? 0 : 3, // Retrasa la animación en 7 segundo
                      opacity: { duration: 0.5 } // Animación de opacidad
                      }}
                    >
                      <img src={Grano1}  />
                    </motion.div>
                </div>

                <div className='grano__maizDos' >
                  <motion.div 
                    initial={{ opacity: 0, x: "-100vw" }} // Comienza desde fuera de la pantalla a la derecha
                    //animate={{ opacity: 1, x: 0, scale: [0.5, 1.1, 0.7] }} // Se coloca en su posición final con un efecto de rebote
                    animate={{ 
                            opacity: shouldAnimate ? 1 : 0, 
                            x: shouldAnimate ? 0 : "-100vw" , 
                            scale: [ 0.7, 0.7, 0.7], 
                          }} // Se coloca en su posición final con un efecto de rebote
                    exit={{ opacity: 0 }} 
                    transition={{ 
                      x: { type: 'spring', stiffness: 20, damping: 5 }, // Animación de rebote en el eje x
                      delay: shouldAnimate ? 0 : 3, // Retrasa la animación en 7 segundo
                    opacity: { duration: 0.5 } // Animación de opacidad
                    }}
                    
                  >
                    <img src={Grano2} className='' />
                  </motion.div>
                </div>

                <div className='grano__maizTres' >
                  <motion.div 
                    initial={{ opacity: 0, x: "-100vw" }} // Comienza desde fuera de la pantalla a la derecha
                    //animate={{ opacity: 1, x: 0, scale: [0.5, 1.1, 0.7], rotateZ: [0, -10, 0] }} // Se coloca en su posición final con un efecto de rebote
                    animate={{ 
                      opacity: shouldAnimate ? 1 : 0, 
                      x: shouldAnimate ? 0 : "-100vw" , 
                      scale: [ 0.7, 0.7, 0.7], 
                    }} // Se coloca en su posición final con un efecto de rebote
                    exit={{ opacity: 0 }} 
                    transition={{ 
                    x: { type: 'spring', stiffness: 20, damping: 5 }, // Animación de rebote en el eje x
                    delay: shouldAnimate ? 0 : 3, // Retrasa la animación en 7 segundo
                    opacity: { duration: 0.5 } // Animación de opacidad
                    }}
                    
                  >
                    <img src={Grano3} className='' />
                  </motion.div>
                </div>

                <div className='grano__maizCuatro' >
                  <motion.div 
                    initial={{ opacity: 0, x: "-100vw" }} // Comienza desde fuera de la pantalla a la derecha
                    //animate={{ opacity: 1, x: 0, scale: [0.5, 1.1, 0.7], rotateZ: [0, -10, 0] }} // Se coloca en su posición final con un efecto de rebote
                    animate={{ 
                      opacity: shouldAnimate ? 1 : 0, 
                      x: shouldAnimate ? 0 : "-100vw" , 
                      scale: [ 0.7, 0.7, 0.7], 
                    }} // Se coloca en su posición final con un efecto de rebote
                    exit={{ opacity: 0 }} 
                    transition={{ 
                      x: { type: 'spring', stiffness: 20, damping: 5 }, // Animación de rebote en el eje x
                      delay: shouldAnimate ? 0 : 3, // Retrasa la animación en 7 segundo
                    opacity: { duration: 0.5 } // Animación de opacidad
                    }}
                    
                  >
                    <img src={Grano4} className='' />
                  </motion.div>
                </div>

                <div className='grano__maizCinco' >
                  <motion.div 
                    initial={{ opacity: 0, x: "50vw" }} // Comienza desde fuera de la pantalla a la derecha
                    //animate={{ opacity: 1, x: 0, scale: [0.5, 1.1, 0.7], rotateZ: [0, -10, 0] }} // Se coloca en su posición final con un efecto de rebote
                    animate={{ 
                      opacity: shouldAnimate ? 1 : 0, 
                      x: shouldAnimate ? 0 : "50vw", 
                      scale: [ 0.7, 0.7, 0.7], 
                    }} // Se coloca en su posición final con un efecto de rebote
                    exit={{ opacity: 0 }} 
                    transition={{ 
                      x: { type: 'spring', stiffness: 20, damping: 5 }, // Animación de rebote en el eje x
                      delay: shouldAnimate ? 0 : 3, // Retrasa la animación en 7 segundo
                    opacity: { duration: 0.5 } // Animación de opacidad
                    }}
                    
                  >
                    <img src={Grano5} className='' />
                  </motion.div>
                </div>

                <div className='grano__maizSeis' >
                  <motion.div 
                    initial={{ opacity: 0, x: "50vw" }} // Comienza desde fuera de la pantalla a la derecha
                    //animate={{ opacity: 1, x: 0, scale: [0.5,  1.1, 0.7], rotateZ: [0, -10, 0] }} // Se coloca en su posición final con un efecto de rebote
                    animate={{ 
                      opacity: shouldAnimate ? 1 : 0, 
                      x: shouldAnimate ? 0 : "50vw", 
                      scale: [0.7, 0.7, 0.7], 
                    }} // Se coloca en su posición final con un efecto de rebote
                    exit={{ opacity: 0 }} 
                    transition={{ 
                      x: { type: 'spring', stiffness: 20, damping: 5 }, // Animación de rebote en el eje x
                      delay: shouldAnimate ? 0 : 3, // Retrasa la animación en 7 segundo
                    opacity: { duration: 0.5 } // Animación de opacidad
                    }}
                    
                  >
                    <img src={Grano6} className='' />
                  </motion.div>
                </div>



                
{/*  

                
               

                <div className='grano__maizSiete' >
                  <motion.div 
                    initial={{ opacity: 0, x: "-100vw" }} // Comienza desde fuera de la pantalla a la derecha
                    animate={{ opacity: 1, x: 0, scale: [0.5, 2.8, 2.2], rotateZ: [0, -10, 0] }} // Se coloca en su posición final con un efecto de rebote
                    exit={{ opacity: 0 }} 
                    transition={{ 
                      x: { type: 'spring', stiffness: 20, damping: 5 }, // Animación de rebote en el eje x
                    opacity: { duration: 0.5 } // Animación de opacidad
                    }}
                    
                  >
                    <img src={Grano7} className='' />
                  </motion.div>
                </div>
*/}

              </div>

          </div>
            
        </div>
      </div>
        </>



   
      



    </>
  )
}



export default MainHomeHBlanca;


















{/* 
      <motion.div
        initial={{ x: '-100vw' }} // Posición inicial fuera del área visible a la izquierda
        animate={{ x: 0, transitionEnd: { x: '0vw', ease: 'easeOut' } }} // Animación hacia la derecha con desaceleración
        transition={{ duration: 2, ease: 'easeInOut' }} // Duración de la animación y tipo de interpolación
      >
        <img
          src={kaly}
          alt="Descripción de la imagen"
          style={{ width: '800px', height: 'auto' }} // Estilo para el tamaño de la imagen
        />
      </motion.div>


      <motion.div
       initial={{ scale: 7, opacity: 0 }} // Escala inicial grande y opacidad cero
       animate={{ scale: 1, opacity: 1, transitionEnd: { scale: 1, ease: 'easeOut' } }} // Animación de reducción de tamaño con desaceleración al final
       transition={{ duration: 1, ease: 'easeOut' }} // Duración de la animación más corta y tipo de interpolación rápida
      >
        <img
          src={kaly}
          alt="Descripción de la imagen"
          style={{ width: '800px', height: 'auto' }} // Estilo para el tamaño de la imagen
        />
      </motion.div>

*/}