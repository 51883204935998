import React from 'react';
import './ClientesNacional.css';

//imagenes
import alcance from 'static/images/home/NationalMomentum.jpg';


const Compromiso = () => {
  return (
    <>
    <div className='body-container' style={{backgroundColor:'#f4f6e4'}}>

      <div className='comp-container-alcance'  >

        {/* Imagen Principal Desktop*/}
        <div
            className='comp-wrap-container-alcance'
            data-aos="fade-left"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-once="true" 
            data-aos-duration="500"
          >
            <a className='comp-wrap-alcance' >
                <img
                  className='comp-img-alcance'
                  alt='Impacto Nacional'
                  src={ alcance }
                />
            </a>

        </div>

        {/* Imagen Principal Movil*/}
        <div
            className='comp-wrap-container-alcance--Movil'
            data-aos="fade-up"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-once="true" 
            data-aos-duration="500"
          >
            <a className='comp-wrap-two' >
                <img
                  className='comp-img-two'
                  alt='Sede AgroMax'
                  src={ alcance }
                />
            </a>

        </div>


          {/* Info */}
          <div className='comp-wrap-Info-alcance'>

                <h1 className='title__home__Protest-center'
                              data-aos="fade-down"
                              data-aos-offset="200"
                              data-aos-easing="ease-in-sine"
                              data-aos-once="true" 
                              data-aos-duration="400"
                > 
                Alcance Nacional
                </h1>
                
              <div className='comp-text'>
                  <p className='text-basic-second-c'
                      data-aos="fade-up"
                      data-aos-offset="200"
                      data-aos-easing="ease-in-sine"
                      data-aos-once="true" 
                      data-aos-duration="400"
                  >
                  Con más de 400 clientes a lo largo y ancho del país, 
                  nuestra presencia nacional es sólida y en constante expansión.
                  </p>
                  { /*Btn de Funciones 
                  <div className='btn-featuredProducts' data-aos="zoom-in" data-aos-once="true" data-aos-duration="1000">
                    <a href='#' style={{textDecoration:'none'}}>
                      <button className='btn-outline-Main'
                        >Descubre Más
                      </button>
                    </a>
                  </div>
                  */ }
              </div>
          </div>

          
      </div>
    </div>
  </>
  )
}

export default Compromiso