import React, { useEffect, useRef } from 'react';
import { TypeAnimation } from 'react-type-animation';
import { motion } from 'framer-motion';


//imagenes HarinaMovil
import HarinaKaly from 'static/images/home/HARINA.png';
import Grano1 from 'static/images/home/GRANO 1.png';
import Grano2 from 'static/images/home/GRANO 2.png';
import Grano3 from 'static/images/home/GRANO 3.png';
import Grano4 from 'static/images/home/GRANO 4.png';
import Grano5 from 'static/images/home/GRANO 5.png';
import Grano6 from 'static/images/home/GRANO 6.png';
import Grano7 from 'static/images/home/GRANO 7.png';
import Canvas from './canvas';
import HarinaMovil from './canvas';

//Estilos y diseño
import './MainHome.css'
import 'aos/dist/aos.css'; 


const MainHome = () => {


  return (
    <>

    <div className='body-container-BackgroundImg'></div>

   
      <div className='body-container-MainHome'>

        {/* ----------- Desktop ----------------*/}
        <div className='main-container' >
            {/* Titulo  */}
            <div className='main__title__container'
                data-aos="fade-right"
                data-aos-offset="200"
                data-aos-easing="ease-in-sine"
                data-aos-once="true" 
                data-aos-duration="600"
            >
              <div className='main__title'>
                  <h1 className='title-basic-main-prin'> Descubre la auténtica tradición venezolana </h1> 
                  {/* 
                  <h1 className='title-basic-main-prin' style={{ color: '#ffff'}}>
                    <TypeAnimation
                      sequence={[
                        // Same substring at the start will only be typed once, initially
                        ""  ,
                        1000,
                        "con Harina Kaly"  ,
                        1000,
                        ""  ,
                        1000,
                        "Sabor y Calidad"  ,
                        1000,
                      ]}
                      speed={40}
                      repeat={Infinity}
                    />
                  </h1>
                  */}
              </div>

                
                <div className='us-text'>
                    { /*Btn de Funciones */ }
                    <div className='btn-featuredProducts' data-aos="zoom-in" data-aos-once="true" data-aos-duration="1000">
                      <a href='/Nosotros' style={{textDecoration:'none'}}>
                        <button className='btn-outline-Main'
                          >Descubre Más
                        </button>
                      </a>
                    </div>
                </div>
            </div>


            {/* Composición Harina de Maiz Amarilla Desktop  */}
            <div className='container-harina-main'>
              
                {/* Harina Amarilla */}
                <div className='empaque__Harina__Amarilla-New' >
                    <motion.div 
                      initial={{ opacity: 0, x: "100vw" }} // Comienza desde fuera de la pantalla a la derecha
                      animate={{ opacity: 1, x: 0, scale: [0.5, 1.4, 1.2], rotateZ: [0, -10, 0] }} // Se coloca en su posición final con un efecto de rebote
                      exit={{ opacity: 0 }} 
                      transition={{ duration: 2, type: 'spring',
                      stiffness: 100, damping: 15 ,
                      x: { type: 'spring', stiffness: 100, damping: 15 }, // Animación de rebote en el eje x
                      opacity: { duration: 0.5 } // Animación de opacidad
                      }}
                      
                    >
                      <img src={HarinaKaly} className=''/>
                    </motion.div>
                </div>

                {/* Granos de Maiz */}
                <div className='granos__Maiz'>

                  <div className='grano__maizUno' >
                      <motion.div 
                        initial={{ opacity: 0, x: "-100vw" }} // Comienza desde fuera de la pantalla a la derecha
                        animate={{ opacity: 1, x: 0, scale: [0.5, 2.8, 2.2] }} // Se coloca en su posición final con un efecto de rebote
                        exit={{ opacity: 0 }} 
                        transition={{ 
                        x: { type: 'spring', stiffness: 20, damping: 5 }, // Animación de rebote en el eje x
                        opacity: { duration: 0.5 } // Animación de opacidad
                        }}
                      >
                        <img src={Grano1}  />
                      </motion.div>
                  </div>

                  <div className='grano__maizDos' >
                    <motion.div 
                      initial={{ opacity: 0, x: "100vw" }} // Comienza desde fuera de la pantalla a la derecha
                      animate={{ opacity: 1, x: 0, scale: [0.5, 2.8, 2.2] }} // Se coloca en su posición final con un efecto de rebote
                      exit={{ opacity: 0 }} 
                      transition={{ 
                        x: { type: 'spring', stiffness: 20, damping: 5 }, // Animación de rebote en el eje x
                      opacity: { duration: 0.5 } // Animación de opacidad
                      }}
                      
                    >
                      <img src={Grano2} className='' />
                    </motion.div>
                  </div>

                  <div className='grano__maizTres' >
                    <motion.div 
                      initial={{ opacity: 0, x: "100vw" }} // Comienza desde fuera de la pantalla a la derecha
                      animate={{ opacity: 1, x: 0, scale: [0.5, 2.8, 2.2], rotateZ: [0, -10, 0] }} // Se coloca en su posición final con un efecto de rebote
                      exit={{ opacity: 0 }} 
                      transition={{ 
                      x: { type: 'spring', stiffness: 20, damping: 5 }, // Animación de rebote en el eje x
                      opacity: { duration: 0.5 } // Animación de opacidad
                      }}
                      
                    >
                      <img src={Grano3} className='' />
                    </motion.div>
                  </div>

                  <div className='grano__maizCuatro' >
                    <motion.div 
                      initial={{ opacity: 0, x: "-100vw" }} // Comienza desde fuera de la pantalla a la derecha
                      animate={{ opacity: 1, x: 0, scale: [0.5, 2.8, 2.2], rotateZ: [0, -10, 0] }} // Se coloca en su posición final con un efecto de rebote
                      exit={{ opacity: 0 }} 
                      transition={{ 
                        x: { type: 'spring', stiffness: 20, damping: 5 }, // Animación de rebote en el eje x
                      opacity: { duration: 0.5 } // Animación de opacidad
                      }}
                      
                    >
                      <img src={Grano4} className='' />
                    </motion.div>
                  </div>

                  <div className='grano__maizCinco' >
                    <motion.div 
                      initial={{ opacity: 0, x: "-100vw" }} // Comienza desde fuera de la pantalla a la derecha
                      animate={{ opacity: 1, x: 0, scale: [0.5, 2.8, 2.2], rotateZ: [0, -10, 0] }} // Se coloca en su posición final con un efecto de rebote
                      exit={{ opacity: 0 }} 
                      transition={{ 
                        x: { type: 'spring', stiffness: 20, damping: 5 }, // Animación de rebote en el eje x
                      opacity: { duration: 0.5 } // Animación de opacidad
                      }}
                      
                    >
                      <img src={Grano5} className='' />
                    </motion.div>
                  </div>

                  <div className='grano__maizSeis' >
                    <motion.div 
                      initial={{ opacity: 0, x: "100vw" }} // Comienza desde fuera de la pantalla a la derecha
                      animate={{ opacity: 1, x: 0, scale: [0.5, 2.8, 2.2], rotateZ: [0, -10, 0] }} // Se coloca en su posición final con un efecto de rebote
                      exit={{ opacity: 0 }} 
                      transition={{ 
                        x: { type: 'spring', stiffness: 20, damping: 5 }, // Animación de rebote en el eje x
                      opacity: { duration: 0.5 } // Animación de opacidad
                      }}
                      
                    >
                      <img src={Grano6} className='' />
                    </motion.div>
                  </div>

                  <div className='grano__maizSiete' >
                    <motion.div 
                      initial={{ opacity: 0, x: "-100vw" }} // Comienza desde fuera de la pantalla a la derecha
                      animate={{ opacity: 1, x: 0, scale: [0.5, 2.8, 2.2], rotateZ: [0, -10, 0] }} // Se coloca en su posición final con un efecto de rebote
                      exit={{ opacity: 0 }} 
                      transition={{ 
                        x: { type: 'spring', stiffness: 20, damping: 5 }, // Animación de rebote en el eje x
                      opacity: { duration: 0.5 } // Animación de opacidad
                      }}
                      
                    >
                      <img src={Grano7} className='' />
                    </motion.div>
                  </div>


                </div>
            </div>

        </div>





        {/* ----------- Movil ----------------*/}
        <div className='us-container-Movil' >

          {/* Titulo */}
          <div className='us-wrap-Info-Movil'>
                      <div
                      data-aos="fade-up"
                      data-aos-offset="200"
                      data-aos-easing="ease-in-sine"
                      data-aos-once="true" 
                      data-aos-duration="1300"
                      >
                        <h1 className='title-basic-main-prin'> 
                        Descubre la <br /> auténtica tradición <br /> venezolana          
                        </h1>
                      </div>

                      <div className='us-text'>
                        { /*Btn de Funciones */ }
                        <div className='btn-featuredProducts' data-aos="zoom-in" data-aos-once="true" data-aos-duration="1000">
                          <a href='/Nosotros' style={{textDecoration:'none'}}>
                            <button className='btn-outline-Main'
                              >Descubre Más
                            </button>
                          </a>
                        </div>
                      </div>
                   
          </div>

           {/* Composición Harina de Maiz Amarilla Desktop  */}
           <div className='container-harina-main-movil'>
              
              {/* Harina Amarilla */}
              <div className='empaque__Harina__Amarilla-New-movil' >
                  <motion.div 
                    initial={{ opacity: 0, x: "5vw" }} // Comienza desde fuera de la pantalla a la derecha
                    animate={{ opacity: 1, x: 0, y: -20, scale: [0.5, 1, 0.81], rotateZ: [0, -10, 0] }} // Se coloca en su posición final con un efecto de rebote
                    exit={{ opacity: 0 }} 
                    transition={{ duration: 2, type: 'spring',
                    stiffness: 100, damping: 15 ,
                    x: { type: 'spring', stiffness: 100, damping: 15 }, // Animación de rebote en el eje x
                    opacity: { duration: 0.5 } // Animación de opacidad
                    }}
                    
                  >
                    <img src={HarinaKaly} />
                  </motion.div>
              </div>

              {/* Granos de Maiz */}
              <div className='granos__Maiz'>
                
                <div className='grano__maizUno' >
                    <motion.div 
                      initial={{ opacity: 0, x: "-100vw" }} // Comienza desde fuera de la pantalla a la derecha
                      animate={{ opacity: 1, x: 0, scale: [0.5, 1.5, 0.7] }} // Se coloca en su posición final con un efecto de rebote
                      exit={{ opacity: 0 }} 
                      transition={{ 
                      x: { type: 'spring', stiffness: 20, damping: 5 }, // Animación de rebote en el eje x
                      opacity: { duration: 0.5 } // Animación de opacidad
                      }}
                    >
                      <img src={Grano1}  />
                    </motion.div>
                </div>

                <div className='grano__maizDos' >
                  <motion.div 
                    initial={{ opacity: 0, x: "-100vw" }} // Comienza desde fuera de la pantalla a la derecha
                    animate={{ opacity: 1, x: 0, scale: [0.5, 1.1, 0.7] }} // Se coloca en su posición final con un efecto de rebote
                    exit={{ opacity: 0 }} 
                    transition={{ 
                      x: { type: 'spring', stiffness: 20, damping: 5 }, // Animación de rebote en el eje x
                    opacity: { duration: 0.5 } // Animación de opacidad
                    }}
                    
                  >
                    <img src={Grano2} className='' />
                  </motion.div>
                </div>

                <div className='grano__maizTres' >
                  <motion.div 
                    initial={{ opacity: 0, x: "-100vw" }} // Comienza desde fuera de la pantalla a la derecha
                    animate={{ opacity: 1, x: 0, scale: [0.5, 1.1, 0.7], rotateZ: [0, -10, 0] }} // Se coloca en su posición final con un efecto de rebote
                    exit={{ opacity: 0 }} 
                    transition={{ 
                    x: { type: 'spring', stiffness: 20, damping: 5 }, // Animación de rebote en el eje x
                    opacity: { duration: 0.5 } // Animación de opacidad
                    }}
                    
                  >
                    <img src={Grano3} className='' />
                  </motion.div>
                </div>

                <div className='grano__maizCuatro' >
                  <motion.div 
                    initial={{ opacity: 0, x: "-100vw" }} // Comienza desde fuera de la pantalla a la derecha
                    animate={{ opacity: 1, x: 0, scale: [0.5, 1.1, 0.7], rotateZ: [0, -10, 0] }} // Se coloca en su posición final con un efecto de rebote
                    exit={{ opacity: 0 }} 
                    transition={{ 
                      x: { type: 'spring', stiffness: 20, damping: 5 }, // Animación de rebote en el eje x
                    opacity: { duration: 0.5 } // Animación de opacidad
                    }}
                    
                  >
                    <img src={Grano4} className='' />
                  </motion.div>
                </div>

                <div className='grano__maizCinco' >
                  <motion.div 
                    initial={{ opacity: 0, x: "50vw" }} // Comienza desde fuera de la pantalla a la derecha
                    animate={{ opacity: 1, x: 0, scale: [0.5, 1.1, 0.7], rotateZ: [0, -10, 0] }} // Se coloca en su posición final con un efecto de rebote
                    exit={{ opacity: 0 }} 
                    transition={{ 
                      x: { type: 'spring', stiffness: 20, damping: 5 }, // Animación de rebote en el eje x
                    opacity: { duration: 0.5 } // Animación de opacidad
                    }}
                    
                  >
                    <img src={Grano5} className='' />
                  </motion.div>
                </div>

                <div className='grano__maizSeis' >
                  <motion.div 
                    initial={{ opacity: 0, x: "50vw" }} // Comienza desde fuera de la pantalla a la derecha
                    animate={{ opacity: 1, x: 0, scale: [0.5,  1.1, 0.7], rotateZ: [0, -10, 0] }} // Se coloca en su posición final con un efecto de rebote
                    exit={{ opacity: 0 }} 
                    transition={{ 
                      x: { type: 'spring', stiffness: 20, damping: 5 }, // Animación de rebote en el eje x
                    opacity: { duration: 0.5 } // Animación de opacidad
                    }}
                    
                  >
                    <img src={Grano6} className='' />
                  </motion.div>
                </div>



                
{/*  

                
               

                <div className='grano__maizSiete' >
                  <motion.div 
                    initial={{ opacity: 0, x: "-100vw" }} // Comienza desde fuera de la pantalla a la derecha
                    animate={{ opacity: 1, x: 0, scale: [0.5, 2.8, 2.2], rotateZ: [0, -10, 0] }} // Se coloca en su posición final con un efecto de rebote
                    exit={{ opacity: 0 }} 
                    transition={{ 
                      x: { type: 'spring', stiffness: 20, damping: 5 }, // Animación de rebote en el eje x
                    opacity: { duration: 0.5 } // Animación de opacidad
                    }}
                    
                  >
                    <img src={Grano7} className='' />
                  </motion.div>
                </div>
*/}

              </div>

          </div>
            
        </div>
      </div>



    </>
  )
}



export default MainHome;


















{/* 
      <motion.div
        initial={{ x: '-100vw' }} // Posición inicial fuera del área visible a la izquierda
        animate={{ x: 0, transitionEnd: { x: '0vw', ease: 'easeOut' } }} // Animación hacia la derecha con desaceleración
        transition={{ duration: 2, ease: 'easeInOut' }} // Duración de la animación y tipo de interpolación
      >
        <img
          src={kaly}
          alt="Descripción de la imagen"
          style={{ width: '800px', height: 'auto' }} // Estilo para el tamaño de la imagen
        />
      </motion.div>


      <motion.div
       initial={{ scale: 7, opacity: 0 }} // Escala inicial grande y opacidad cero
       animate={{ scale: 1, opacity: 1, transitionEnd: { scale: 1, ease: 'easeOut' } }} // Animación de reducción de tamaño con desaceleración al final
       transition={{ duration: 1, ease: 'easeOut' }} // Duración de la animación más corta y tipo de interpolación rápida
      >
        <img
          src={kaly}
          alt="Descripción de la imagen"
          style={{ width: '800px', height: 'auto' }} // Estilo para el tamaño de la imagen
        />
      </motion.div>

*/}