import React, { Suspense, useState, useEffect, lazy } from 'react';
import AOS from 'aos'; 

//componentes
import { useMediaQuery } from 'react-responsive';
 
//imagenes 
import NosotrosDesktop from 'static/images/home/nosotrosMicevenDesktop.jpg'; 
import NosotrosMovil from 'static/images/home/nosotrosMiceven.jpg'; 
 
 
//Diseño y estilos 
import './AboutUs.css'; 
import 'Styles/GlobalStyles.css'; 
 
//Estilos y diseño 
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css"; 
import 'aos/dist/aos.css';  
import zIndex from '@mui/material/styles/zIndex'; 
 
 
 
AOS.init(); 
 


// Importa el componente Model utilizando React.lazy()
const CaminataSaludo = lazy(() => import('../Home/MuñecaKaly/CaminataSaludo'));
 
const AboutUs = () => { 

    const [showAnimation, setShowAnimation] = useState(false);

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-device-width: 1224px)'
      });


  return ( 
    <> 
        <div className='body-container '  style={{ background: '#f9faf7' }}>  
                                
 
                            {/*Desktop*/} 
                            <div className='us-container__trayectory'> 
                                {/* Imagen Principal */} 
                                <div className='us-wrap__trayectory' data-aos="fade-right" data-aos-once="true" data-aos-duration="800"  
                                    style={{zIndex:"30"}}> 
 
                                    <img 
                                    style={{position:"relative", zIndex:"30"}} 
                                    alt='Sede AgroMax' 
                                    src={NosotrosDesktop} 
                                    /> 
                                </div> 
 
                                {/* Info */} 
                                <div className='us-wrap-Info__trayectory' style={{ position:'relative', zIndex:"10"}} 
                                > 
                                         
                                        <h2 className='subtitle__home' style={{ textAlign:"start"}} 
                                             data-aos="fade-down" data-aos-once="true" data-aos-duration="800" 
                                        >  
                                                ¿Quienes Somos? 
                                        </h2> 
 
                                        <h1 className='title__home__Protest' style={{ textAlign:"start"}}>  
                                            <div data-aos="fade-down" data-aos-once="true" data-aos-duration="800" >  
                                                Somos una de las principales empresas productoras de  
                                            </div>  
                                            <div className='title__home__Protest-Border'  
                                                    data-aos="zoom-in" data-aos-once="true" data-aos-duration="800" >
                                                        Harina de Maíz  
                                            </div>  
                                        </h1> 

                                </div> 

                                    {/* Animación Caminata Muñeca */}
                                        

                                          
                                        


                                
                                    {/* 
                                    <Suspense fallback={null}>
                                    <div style={{  display: "flex", position: "absolute", right: "0%" }}> 
                                        <div style={{  display: "flex", width:"600px",height: "600px", position: "relative"}}> 
                                        <Lottie 
                                            options={defaultOptionsSaludoKaly} 
                                            loop={true} 
                                            speed={1} 
                                            style={{ width: 500, height: 500, position: "absolute", zIndex: "20", top: "100px", right: "0%", marginRight: "0%" }}
                                        /> 
                                        </div>  
                                    </div>  
                                    </Suspense>
                                    */}


                            </div> 
 
                            {/*Movil*/} 
                            <div className='us-container__trayectory-movil'> 
                             
                                {/* Info */} 
                                <div className='us-wrap-Info__trayectory' 
                                > 
                                        <h2 className='subtitle__home' style={{ textAlign:"start"}} 
                                             data-aos="fade-down" data-aos-once="true" data-aos-duration="800" 
                                        >  
                                                ¿Quienes Somos? 
                                        </h2> 
 
                                        <h1 className='title__home__Protest' style={{ textAlign:"start"}}>  
                                            <div data-aos="fade-down" data-aos-once="true" data-aos-duration="800" >  
                                                Somos una de las principales empresas productoras de  
                                            </div>  
                                            <div className='title__home__Protest-Border'  
                                                    data-aos="zoom-in" data-aos-once="true" data-aos-duration="800" >  
                                                Harina de Maíz  
                                            </div>  
                                        </h1> 
                                </div>   

                                
                                        
                                

                                {/*
                                <Suspense fallback={null}>
                                        <div style={{  display: "flex", position: "absolute", right: "0%" }}> 
                                            <div style={{  display: "flex", width:"350px",height: "350px", position: "relative"}}> 
                                                <Lottie 
                                                    options={defaultOptionsSaludoKaly} 
                                                    loop={true} 
                                                    speed={1} 
                                                    style={{ width: 350, height: 350, position: "absolute", zIndex: "20", top: "50px", right: "0%",  marginLeft: "auto" , marginBottom: "0%" }}
                                                /> 
                                            </div>
                                        </div>
                                    </Suspense>
                                */}

                                    


 
                                {/* Imagen Principal */} 
                                <div className='us-wrap__trayectory-movil' style={{ marginTop:"330px" }} 
                                data-aos="fade-up" data-aos-once="true" data-aos-duration="800" 
                                > 
                                    <img 
                                        className='us-img__trayectory' 
                                        alt='Sede AgroMax' 
                                        src={ NosotrosMovil } 
                                    /> 
                                   
                                </div> 
                            </div>


                            <div style={{ display: "flex", position: "absolute", right: "0%" }} > 
                                <div style={isDesktopOrLaptop ? {display: "flex", width: "600px", height: "600px", position: "relative" } 
                                                                  : {  display: "flex", width:"350px",height: "350px", position: "relative"}}> 
                                    <Suspense fallback={<div>Loading animation...</div>}>
                                        <CaminataSaludo Tamaño={isDesktopOrLaptop ? "Desktop" : "Movil"} />
                                    </Suspense>
                                </div>  
                            </div>

        </div> 
    </> 
  ) 
} 
 
export default AboutUs;