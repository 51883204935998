import React from 'react';

//Logo Footer 
import  micevenFooter   from "../../../../static/images/Navbar/micevenF1.png";

//icons
//social Icons  
import { FaFacebookF } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";
import { MdLocationOn } from "react-icons/md";


//estilos
import './Footer.css'


const Footer = () => {
    return (
        <footer className="color__footer">
            {/* Contenedor Principal */}
            <div className="footer__container">

                {/* Logo */}
                <div className="footer__Logo" >
                        <a href='/'>
                            <div className='footer__logo__img' >
                                <img src={ micevenFooter }  alt="" />
                            </div> 
                        </a> 

                        {/*Social icons */}
                        <div className='footer__logo__IconsSocial'>
                            <a href='https://www.facebook.com/p/Miceven-CA-100066499841192/' target='_blank' aria-label='Facebook'>
                                    <FaFacebookF className='footer__logo__IconsSocial__item'/>
                            </a>
                            <a href='https://www.instagram.com/harinakalyve/?hl=es' target='_blank' aria-label='Instagram'>
                                    <BsInstagram className='footer__logo__IconsSocial__item'/>
                            </a>
                            <a href='https://twitter.com/MICEVENCA' target='_blank' aria-label='Twitter'>
                                    <BsTwitter className='footer__logo__IconsSocial__item'/>
                            </a>
                        </div>
                </div>

                {/* Footer */}
                <div className="footer__info">

                    {/* Categorias */}
                    <div className="footer__info__products"> 
                        <ul className="footer__info__products__list" >
                            <li className='footer__info__products__item'>
                                <a href='/'>
                                    <span className={'footer-links'}>Inicio</span>
                                </a> 
                            </li>
                            <li className='footer__info__products__item'>
                                <a href='/Nosotros'>    
                                    <span className={'footer-links'} >Nosotros</span>
                                </a> 
                            </li>
                            <li className='footer__info__products__item'>
                                <a href='/Contacto'>
                                    <span className={'footer-links'} >Contacto</span>
                                </a> 
                            </li>
                        </ul>

                    </div>

                    {/* Información de Contacto */}
                    <div className="footer__info__contact"> 
                        <div className='footer__info__contact__list'>
                            <div className='footer__info__contact__item'>
                                <div className='footer__info__contact__iconText'>
                                    <MdLocationOn className='icon-Location-Footer' />
                                    <span className='footer__contact__span' >Av Páez, Local Nro S/N. Zona Industrial de Araure - Sector Miraflores.
                                        Araure - Portuguesa</span>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>

            {/* Copyright Casagri */}
            <div className="text-center text-Copy">MICEVEN © 2024 - All Rights Reserved</div>
            
        </footer>
    )
}

export default Footer; 