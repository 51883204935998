import React, { useState } from 'react';
import styled from 'styled-components';
import 'aos/dist/aos.css'; 
import './ContactSection.css';
import './InscriptionForm.css';

const FormStyle = styled.form`
  width: 100%;
  .form-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
  }
`;

export default function ContactForm({Component}) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  return (
    <div className={ Component == "Contacto" ? 'container__Form-Contact': 'container__Form'}
          data-aos="fade-up" data-aos-once="true" data-aos-duration="1500">
        <form className={ Component == "Contacto" ? 'container__Form-form-Contact': 'container__Form-form'}>
          {/* Nombre */}
          <div className={ Component == "Contacto" ? 'Container__Label-Email-Contact': 'Container__Label-Email'} >
            <label className={ Component == "Contacto" ? 'Label__Form-Contact': 'Label__Form'} htmlFor="name">
              Nombre
            </label>
            <input
                type="text"
                className={ Component == "Contacto" ? 'Input__Form-Contact': 'Input__Form'}
                id="name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
          </div>

          {/* Correo Eléctrónico  */}
          <div className={ Component == "Contacto" ? 'Container__Label-Contact' : 'Container__Label'}>
            <label className={ Component == "Contacto" ? 'Label__Form-Contact': 'Label__Form'} htmlFor="email">
              Correo Electrónico
            </label>
            <input
                type="email"
                className={ Component == "Contacto" ? 'Input__Form-Contact': 'Input__Form'}
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
          </div>

          {/* Correo Mensaje */}
          <div className={ Component == "Contacto" ? 'Container__Label-Contact' : 'Container__Label'}>
            <label className={ Component == "Contacto" ? 'Label__Form-Contact': 'Label__Form'} htmlFor="message">
              Mensaje
            </label>
            <textarea
                type="text"
                className={ Component == "Contacto" ? 'Input__Form-Contact': 'Input__Form'}
                id="message"
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              />
          </div>

          { /*Btn de Funciones */ }
          <div className='btn-container' data-aos="zoom-in" data-aos-once="true" data-aos-duration="1000">
              <button type="submit" className='btn-outline-Main'
                >Enviar
              </button>
          </div>
        </form>
        
    </div>
  );
}
