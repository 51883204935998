import React, {useState} from 'react';
import Carousel from 'react-bootstrap/Carousel';

//Componentes
import MainHome from 'components/Home/MainHome';
import MainHomeHBlanca from 'components/Home/MainHomeHBlanca';


//Iconos
import { AiOutlineRight, AiOutlineLeft  } from "react-icons/ai";



const CustomNextButton = ({ onClick }) => (
    <button className="custom-carousel-button next" onClick={onClick}>
        <AiOutlineRight />
    </button>
);

const CustomPrevButton = ({ onClick }) => (
    <button className="custom-carousel-button prev" onClick={onClick}>
        <AiOutlineLeft />
    </button>
);

const BannerMainCarruselHome = () => {

    const [isVisible, setIsVisible] = useState(false);


  return (
    <> 
     
            <Carousel
                fade
                indicators={false}
                nextIcon={<CustomNextButton  onClick={() => setIsVisible(true)} />}
                prevIcon={<CustomPrevButton  onClick={() => setIsVisible(true)} />}
                className="rounded-carousel-Main" 
                interval={3300} 
            >

                    <Carousel.Item key={`${'component'}-${'desktop'}-${'index'}`}>
                        <MainHome />
                    </Carousel.Item>

                    <Carousel.Item key={`${'component'}-${'desktop'}-${'index-two'}`}>
                        <MainHomeHBlanca Visible={isVisible} />
                    </Carousel.Item>


            </Carousel>
        </>
  )
}

export default BannerMainCarruselHome;