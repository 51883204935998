

import React, { useRef, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';

//imagenes barinasWeb-Final


//Estilos y diseño
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'aos/dist/aos.css';
import './Companys.css'; 

//Icons 
import { AiFillPhone } from "react-icons/ai";
import { MdLocationOn } from "react-icons/md";
import { HiMail } from "react-icons/hi";




const Companys = ({Loanding}) => {

  // Crea una referencia para el contenedor del mapa
  const mapContainerLara = useRef(null); 

  useEffect(() => {

    //Configurar las instancia de los mapas
    const mapLara = new mapboxgl.Map({
      container: mapContainerLara.current,
      style: 'mapbox://styles/mapbox/streets-v12', // style URL
      center: [ -69.1788489990984, 9.588671268612881 ], // 
      zoom: 11.8, // Nivel de zoom inicial
    });


    // Crea una instancia de Popup y agrega un título
    const popup = new mapboxgl.Popup().setHTML("<h6>Miceven</h6>");
        
    const markerLara = new mapboxgl.Marker()
        .setLngLat([ -69.1788489990984, 9.588671268612881 ])
        .setPopup(popup)
        .addTo(mapLara);



    // Agregar una capa de texto al mapa para mostrar el título
    mapLara.on('load', function () {
      mapLara.addLayer({
        id: 'title-layer',
        type: 'symbol',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                properties: {},
                geometry: {
                  type: 'Point',
                  coordinates: [ -69.1788489990984, 9.588671268612881 ], // Coordenadas de Nueva York como ejemplo
                },
              },
            ],
          },
        },
        layout: {
          'text-field': 'Miceven',
          'text-size': 14,
          'text-anchor': 'top',
        },
        paint: {
          'text-color': '#000000',
        },
      });
    });





    // Limpia los mapas al desmontar el componente
    return () => {
      mapLara.remove();
    };
  }, []); // El segundo argumento vacío asegura que se ejecute solo una vez al montar el componente



  return (
    <>
      <div className=''>

      {/* -----------   Casagri de Lara  ----------------*/}
      <div className='container__main__sede'>

        <div className='title_sede'>
          <h1 className='title__home__Protest-center' > 
            Miceven C.A.
          </h1>
          
          <div className='Contact-text' style={{paddingLeft:'0.5rem'}}>
            <MdLocationOn className='icon-Contact'/> 
            <p className='text-basic-second-Ubi'>Ubicación: Av Paez, Nro S/N Zona Industrial de Araure, Araure 3303, Portuguesa, Venezuela </p>
          </div>

          <div className='Contact-text' style={{paddingLeft:'0.5rem'}}>
            <AiFillPhone className='icon-Contact-phone' /> 
            <p className='text-basic-second-Ubi'>Teléfono: +58 2559 355556 </p>
          </div>

          <div className='Contact-text' style={{paddingLeft:'0.5rem'}}>
            <HiMail className='icon-Contact-phone' /> 
            <p className='text-basic-second-Ubi'>Correo: atencionclinteskaly@miceven.com </p>
          </div>
          
        </div>

        <div
          className='maps_center__comp'
          ref={mapContainerLara}
          data-aos="fade-up" data-aos-once="true" data-aos-duration="800"
        />
        </div>


    </div>

    </>
  )
}

export default Companys;
