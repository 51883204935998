import React, { Suspense, lazy } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { motion } from "framer-motion-3d";

//Diseño
import './HarinaStyle.css'

//Iconos
import { AiFillStepBackward , AiFillStepForward } from "react-icons/ai";


function HarinaAmarilla ({ Material, Tamaño }) {

  // Importa el componente Model utilizando React.lazy()
  const LazyEmpaqueVerde = lazy(() => import('./Modelo3D/EmpaqueVerde'));


  return(

    <div className={
            Material === 'Verde' ? (Tamaño === 'Desktop' ? 'harinaBlanca' : 'harinaBlancaMovil') : (Tamaño === 'Desktop' ? 'harinaAmarilla' : 'harinaAmarillaMovil')} 
            >
       <Canvas style={Tamaño === 'Desktop' ? {height: '500px', width:'400px'} : {height: '300px'}}>
            <Suspense fallback={null}>
              <ambientLight intensity={1.35} /> {/* Luz ambiental */}
              <directionalLight intensity={0.6} position={[3.519, 0.281, 1.332]} />  {/*Luz direccional Lado Kaly */}
              <directionalLight intensity={0.4} position={[-1.519, 0.281, -1.332]} />  {/*Luz direccional Lado Oscuro */}
              <directionalLight intensity={1} position={[-0.519, 0.281, 1.332]} /> {/* Nueva luz direccional Frente */}

              <motion.group 
                initial={{ opacity: 0, x: 0, scale: 0 }} 
                animate={{ opacity: 1, x: 0, scale: ( Tamaño === 'Desktop' ? 1.8 : 1.8 ), rotateY: ( Material === 'Verde' ? [0, 0, 0, 6.5] : [0, 0, 0, 1.05] ) , rotateZ: [0, 0, 0, 0]}} 
                exit={{ opacity: 0 }} 
                transition={{ duration: 2, type: 'spring', stiffness: 40, damping: 15 }}>
                <LazyEmpaqueVerde  Material={Material}/>
              </motion.group>
               

              <OrbitControls enablePan={false} enableZoom={false} enablreRotatew={false}/>
            </Suspense>
        </Canvas>
        <div className='text-indic'>
          <AiFillStepBackward  className='icon-indic' />  Toca para mover el 3D  <AiFillStepForward  className='icon-indic'/> 
        </div>
    </div>
    
  );
  
}

  export default HarinaAmarilla;



