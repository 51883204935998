import React from 'react';


import miceven1 from '../../static/images/banner/miceven1.jpg';
import miceven11 from '../../static/images/banner/miceven11.jpg';

//Estilos
import '../BannerMain/BannerMain.css';
import '../../Styles/GlobalStyles.css';


export const BannerMain = () => {



  return (
    <>
     {/*------------------------------Banner Escritorio------------------------------------*/}

     <section className='Banner-Main-Desktop'>

          <div className='container-Banner-Main'>
            <img className='banner-img' 
              src={miceven1}
              alt="">
            </img>
            <div className='container-Banner-Main-Content'>
              <h1 className='container-Banner-Main-Content-title Gothan-Font w500'></h1>
            </div>
          </div>

     </section>
 

    {/*------------------------------Banner Movil------------------------------------*/}
    <section className='Banner-Main-Movil'>
          <div className='container-Banner-Main-Movil'>
              <img className='banner-img-Movil' 
                src={miceven11}
                alt="Compañia"
                >
              </img>
          </div>
    </section>
  </>
     
  )
}
