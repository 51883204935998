import React, { useState, useEffect  } from 'react';
import { Link } from 'react-router-dom';

//Estilos
import './Navbar.css';
import 'aos/dist/aos.css'; 

//component
import  {TopInfo}  from "./TopInfo"

//Logo Miceven
import  miceven1  from "static/Miceven-logo.svg";





const Navbar = () => {

  //Varibbles de estado
  const [click, setClick] = useState(false);
  const [openNavbar, setOpenNavbar] = useState(false);
  const [button, setButton] = useState(true);
  const [dropdown, setDropdown] = useState(false);
  const [scrolling, setScrolling] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);



  //btn opciones de Movil
  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  //Funcion para refrescar Pagina
  function refreshPage() {
    window.location.reload(false);
  }

  //Funcion para detectar el scroll
  const handleScroll = () => {
    if (window.scrollY === 0) {
      setScrolling(false);
    } else {
      setScrolling(true);
    }
  };

  const toggleMenu = () => {
    setOpenNavbar(!openNavbar);
    console.log(openNavbar);
  };


  useEffect(() => {
    showButton();
    
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    
  }, []);


  //window.addEventListener('resize',  refreshPage);

  //Elementos para hover
  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };




  return (
    <>
      <TopInfo />
      <nav className={scrolling ? (click ? 'navbar-click-scroling' : 'navbar-scroll') : (click ? 'navbar-click' : 'navbar-init')}>

        <a href='/' 
        className={ scrolling ? 'navbar-logo-scrolling' : 'navbar-logo' }
        /*className={'navbar-logo'}*/
        onClick={closeMobileMenu}>
        {
          miceven1 != null ? 
            (<img 
                src={ miceven1  } /*width={"70px"} height={"63.253px"}*/ alt="Miceven" />
               ):null
        }    
        </a>


      {/* 
          <div onClick={handleClick}>
            {
              scrolling ? (
                <i className={click ? 'fas fa-times' : 'fas fa-bars green'} />
              ):(
                <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
              )
            }
          </div>
      */}

          {/* Menu de Opciones Desktop */}
          { openNavbar ? (
          <ul className={ `nav-menu ${openNavbar ? 'mov' : ''}` } 
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-easing="ease-in-sine"
            data-aos-once="true" 
            data-aos-duration="400"
          >
            <li className='nav-item'>
              <Link
                to='/'
                className={scrolling || click ?  'nav-links-scroll':'nav-links'}
                onClick={closeMobileMenu}
              >
                Inicio
              </Link>
            </li>

            <li
              className='nav-item'
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              <Link
                to='/Nosotros'
                className={scrolling || click ?   'nav-links-scroll':'nav-links'}
                onClick={closeMobileMenu}
              >
                Nosotros
              </Link>
            </li>


            <li className='nav-item'>
              <Link
                to='/Contacto'
                className={scrolling || click ?   'nav-links-scroll':'nav-links'}
                onClick={closeMobileMenu}
              >
                Contacto
              </Link>
            </li>

            <li className='nav-item'>
              <Link className='btn-Incripción' to={'/entrar'}>Iniciar Sesión</Link>  
            </li>
        
          </ul>):null
          }

          {/* Icono de Menu Desktop */}
          <div className={`menu-icon-mov ${openNavbar ? 'open' : ''}`} onClick={ toggleMenu } >
                <div></div>
                <div></div>
                <div></div>
          </div>    


          {/* Menu de Opciones Movil */}
          <ul className={click ? 'nav-menu-Movil active' : `nav-menu-Movil` }
          >
            <li className='nav-item'>
              <Link
                to='/'
                className={'nav-links-movil'}
                onClick={closeMobileMenu}
              >
                Inicio
              </Link>
            </li>

            <li
              className='nav-item'
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              <Link
                to='/Nosotros'
                className={'nav-links-movil'}
                onClick={closeMobileMenu}
              >
                Nosotros
              </Link>
            </li>

            <li className='nav-item'>
              <Link
                to='/Contacto'
                className={'nav-links-movil'}
                onClick={closeMobileMenu}
              >
                Contacto
              </Link>
            </li>

            <Link className='nav-item' to='/entrar' style={{textDecoration:'none'}} onClick={closeMobileMenu} >
                  <button className='btn-Incripción' >Iniciar Sesión</button>  
            </Link>
        
          </ul>

          {/* Icono de Menu Movil */}
          <div className={`menu-icon-mov-Movil ${click ? 'open' : ''}`} onClick={ handleClick } >
                <div></div>
                <div></div>
                <div></div>
          </div>   

          
         

      </nav>
    </>
  );
}

export default Navbar;
